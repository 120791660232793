<template>
    <div>
        <Company></Company>
    </div>
</template>
<script>
import {creditInfo} from "@/api/supplier.js";
import Company from '../components/companyDetail1.vue'
export default {
    components: {Company},
    data () {
        return {
            cnCompany: {}
        }
    },
    created() {
        // this.getData()
    },
    methods: {
        // getData() {
        //     this.cnCompany.entname = localStorage.getItem('cnCompany');
        //     this.cnCompany.entname = '上海喜临门家具有限公司';
        //     let data = creditInfo(this.cnCompany)
        // }
    }
}
</script>