<template>
  <div>
    <div v-if="serviceDiv == 1">
      <div class="flex_center_between_box companyDiv">
        <div class="work_business">
          <div class="flex_box work_top">
            <div class="work_top_div" v-show="logoPhoto">
              <img :src="img4">
            </div>
            <div v-if="percent.enCompany">
              <div class="work_business_company">{{percent.cnCompany}}</div>
              <div class="work_business_company_en">{{percent.enCompany}}</div>
            </div>
            <div v-else>
              <div class="work_business_company work_business_company_div">{{percent.cnCompany}}</div>
            </div>
          </div>
          <ul class="work_business_detail">
            <li>
              <div class="flex_box">
                <div class="iconfont icon-xiaopangchezhengxinguanli"></div>
                <div class="work_business_detail_font over">{{percent.creditCore}}</div>
              </div>
              <div class="flex_box min_width">
                <div class="iconfont icon-dianhuatianchong"></div>
                <div class="work_business_detail_font overRight">{{percent.tel}}</div>
              </div>
            </li>
            <li>
              <div class="flex_box">
                <div class="iconfont icon-dizhi"></div>
                <div class="work_business_detail_font over" :title="percent.address">{{percent.address}}</div>
              </div>
              <div class="flex_box min_width">
                <div class="iconfont icon-youjian"></div>
                <div class="work_business_detail_font overRight">{{percent.email}}</div>
              </div>
            </li>
            <li>
              <div class="flex_box">
                <img :src="img" class="work_business_detail_icon">
                <div class="work_business_detail_font over">{{addressInfo}}</div>
              </div>
              <div class="flex_box min_width">
                <div class="iconfont icon-24gf-network"></div>
                <div class="work_business_detail_font overRight">{{percent.network}}</div>
              </div>
            </li>
          </ul>
          <!-- <div class="work_business_person button_show">个人中心</div> -->
        </div>
        <div class="work_chart">
          <div class="work_chart_item flex_center_between_box">
            <div ref="risk1" class="corporateRateLeftEcharts" />
            <div ref="risk2" class="corporateRateLeftEcharts" />
            <div ref="risk3" class="corporateRateLeftEcharts" />
          </div>
          <ul class="work_chart_detail flex_center_between_box">
            <li>
              <div class="work_chart_detail_color color1"></div>
              <div class="work_chart_detail_score color1">{{e_3Aecharts.creditRating}}</div>
              <div class="work_chart_detail_tit">信用评级</div>
            </li>
            <li>
              <div class="work_chart_detail_color color2"></div>
              <div class="work_chart_detail_score color2">{{e_3Aecharts.creditScore}}</div>
              <div class="work_chart_detail_tit">信用评分</div>
            </li>
            <li>
              <div class="work_chart_detail_color color3"></div>
              <div class="work_chart_detail_score color3">{{e_3Aecharts.vioIndex}}</div>
              <div class="work_chart_detail_tit">违约指数</div>
            </li>
            <li>
              <div class="work_chart_detail_color color4"></div>
              <div class="work_chart_detail_score color4">{{e_3Aecharts.payoffIndex}}</div>
              <div class="work_chart_detail_tit">偿还指数</div>
            </li>
            <li>
              <div class="work_chart_detail_color color5"></div>
              <div class="work_chart_detail_score color5">{{e_3Aecharts.creditRiskLevel}}</div>
              <div class="work_chart_detail_tit">信用风险等级</div>
            </li>
          </ul>
          <div class="flex_center_start_box">
            <div class="button_show margin_30" @click="RiskPrediction2()">优化我的信用</div>
            <!-- <div class="button_show" @click="RiskPrediction()">风险预测</div> -->
          </div>
        </div>
      </div>
      <div class="corporate_image">
        <ul class="corporate_image_div flex_center">
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-gongsi"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">公司性质</div>
            <table class="table_line2">
              <tr>
                <td>公司性质</td>
                <td class="over2">{{Alldate.entclass}}</td>
              </tr>
              <tr>
                <td>成立年限</td>
                <td class="over2">{{Alldate.esdate}}</td>
              </tr>
              <tr>
                <td>注册资本</td>
                <td class="over2">{{Alldate.regcap}}</td>
              </tr>
              <tr>
                <td>实付资本</td>
                <td class="over2">{{Alldate.reccap}}</td>
              </tr>
            </table>
          </li>
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-tianjiagudongzhanghu01"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">股东结构</div>
            <table>
              <tr>
                <td>自然人股东</td>
                <td class="over2">{{Alldate.sharehHolders}}</td>
                <td class="corporate_image_tr_td3">{{Alldate.shareHoldersPer}}</td>
              </tr>
              <tr>
                <td class="over1">公司法人股东</td>
                <td class="over2">{{Alldate.inShareHolders}}</td>
                <td class="corporate_image_tr_td3">{{Alldate.inShareHolders}}</td>
              </tr>
              <tr>
                <td>子公司</td>
                <td class="over2">{{Alldate.subEnterprise}}</td>
                <td class="corporate_image_tr_td3">{{Alldate.subEnterpriseFigure}}</td>
              </tr>
              <tr>
                <td>关联公司</td>
                <td class="over2">{{Alldate.relEnterprise}}</td>
                <td class="corporate_image_tr_td3">{{Alldate.reEnterpriseFigure}}</td>
              </tr>
            </table>

          </li>
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-shuishoucaiwu"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">财务表现</div>
            <table>
              <tr>
                <td>销售额</td>
                <td class="over2">--</td>
                <td class=" flex_box corporate_image_tr_td3 up">
                  <!-- <div class="iconfont icon-web-icon-1 float_right"></div> -->
                  <div class="up float_right float_right">--</div>
                </td>
              </tr>
              <tr>
                <td>净利润</td>
                <td class="over2">--</td>
                <td class="flex_box corporate_image_tr_td3 down">
                  <!-- <div class="iconfont icon-web-icon- float_right"></div> -->
                  <div class="up float_right">--</div>
                </td>
              </tr>
              <tr>
                <td>资产总额</td>
                <td class="over2">--</td>
                <td class="flex_box corporate_image_tr_td3 up">
                  <!-- <div class="iconfont icon-web-icon-1 float_right"></div> -->
                  <div class="up float_right">--</div>
                </td>
              </tr>
              <tr>
                <td>负债总额</td>
                <td class="over2">--</td>
                <td class="flex_box corporate_image_tr_td3 up">
                  <!-- <div class="iconfont icon-web-icon-1 float_right"></div> -->
                  <div class="up float_right">--</div>
                </td>
              </tr>
            </table>
          </li>
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-jingying_mian"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">经营现状</div>
            <table class="table_line2">
              <tr>
                <td>主营业务</td>
                <td class="over2">{{Alldate1.mainDescription}}</td>
              </tr>
              <tr>
                <td>主要产品</td>
                <td class="over2">{{Alldate1.mainProduct}}</td>
              </tr>
              <tr>
                <td>覆盖市场</td>
                <td class="over2">--</td>
              </tr>
              <tr>
                <td>员工人数</td>
                <td class="over2">{{Alldate1.staffNum}}</td>
              </tr>
            </table>

          </li>
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-gudongxinxi"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">管理人员</div>
            <table class="table_line2">
              <tr v-for="(item,index) in Alldate.managerPerson" :key="index">
                <td>{{item.name}}</td>
                <td class="over2">{{item.position}}</td>
              </tr>
            </table>

          </li>
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-menu_flyz"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">负面信息</div>
            <table class="table_line2">
              <tr>
                <td>失信名单</td>
                <td class="over2">{{Alldate2.fhshixinInfos}}</td>
              </tr>
              <tr>
                <td>执行公告</td>
                <td class="over2">{{Alldate2.fhzxggInfos}}</td>
              </tr>
              <tr>
                <td>裁判文书</td>
                <td class="over2">{{Alldate2.fhcpwsInfos}}</td>
              </tr>
              <tr>
                <td>开庭公告</td>
                <td class="over2">{{Alldate2.fhktggInfos}}</td>
              </tr>
            </table>

          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="serviceDiv === 2">
      <div class="second_return_icon margin_20 tb">
        <i class="iconfont icon-fanhui" @click="getData()"></i>
      </div>
      <div class="second_title_box margin_B20">
        <span class="second_title1">
          优化我的信用
        </span>
      </div>
      <el-card class="a">
        <div class="heard">
          <div class="gdpgzx">
            <span>更多评估咨询：</span>
            <el-select v-model="value" placeholder="" class="select_class gdpgzx_select">
              <el-option v-for="item in options1" :key="item.codeState" :label="item.value" :value="item.codeState">
              </el-option>
            </el-select>
          </div>
          <div class="year">
            <el-form>
              <el-form-item label="年份:" class="year_form_item" :class="exceptArrivalClass ? 'exceptArrivalClass' : ''">
                <el-date-picker class="date-picker-icon" v-model="formLabelAligns.years" type="year" placeholder="选择日期" prefix-icon="iconfont icon-riqi" value-format="yyyy" format="yyyy"
                  @blur="dateYearChange()" @input="exceptArrivalChange1()" :editable="false">
                </el-date-picker>
              </el-form-item>
            </el-form>
            <!-- <span style="maging-left:-100px">年份：</span> -->

          </div>
          <div class="cgbutton">
            <el-button type="success" round style="" class="anniu" @click="tijiao()">提交</el-button>
          </div>
        </div>
        <div class="dk2">
          <div class="dk3" :key="indexIndexKey">
            <div class="dk3_1">
              <div class="from3">
                <el-form :label-position="labelPosition" :model="formLabelAlign" class="f" ref="formLabelAlign">
                  <div class="fl">
                    <div class="topbutton">
                      <el-form-item label="流动资产合计:" prop="totalCurrentAssets">
                        <el-input v-model.number="formLabelAlign.totalCurrentAssets" class="mc" @input="regNumber1"
                          @blur="formLabelAlign.totalCurrentAssets = filter(formLabelAlign.totalCurrentAssets)" @focus="inputMoneyFocus1(formLabelAlign.totalCurrentAssets)" maxlength="12">
                        </el-input>
                      </el-form-item>
                    </div>
                    <el-form-item label="货币资金:" prop="monetaryCapital">
                      <el-input v-model="formLabelAlign.monetaryCapital" class="mc" @input="regNumber2" @blur="inputMoneyBlur(formLabelAlign.monetaryCapital)"
                        @focus="inputMoneyFocus2(formLabelAlign.monetaryCapital)" maxlength="12"></el-input>
                    </el-form-item>
                    <div class="topbutton2">
                      <el-form-item label="应收账款:" prop="accountsReceivable">
                        <el-input v-model="formLabelAlign.accountsReceivable" class="mc" @input="regNumber3" @blur="formLabelAlign.accountsReceivable = filter(formLabelAlign.accountsReceivable)"
                          @focus="inputMoneyFocus3(formLabelAlign.accountsReceivable)" maxlength="12">
                        </el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="预计款项:" prop="advancePayment">
                        <el-input class="mc" v-model="formLabelAlign.advancePayment" @input="regNumber4" @blur="formLabelAlign.advancePayment = filter(formLabelAlign.advancePayment)"
                          @focus="inputMoneyFocus4(formLabelAlign.advancePayment)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="存货:" prop="stock">
                        <el-input class="mc" v-model="formLabelAlign.stock" @input="regNumber5" @blur="formLabelAlign.stock = filter(formLabelAlign.stock)"
                          @focus="inputMoneyFocus5(formLabelAlign.stock)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="待摊费用:" prop="unamortizedExpense">
                        <el-input class="mc" v-model="formLabelAlign.unamortizedExpense" @input="regNumber6" @blur="formLabelAlign.unamortizedExpense = filter(formLabelAlign.unamortizedExpense)"
                          @focus="inputMoneyFocus6(formLabelAlign.unamortizedExpense)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="from3">
                <el-form :label-position="labelPosition" label-width="100px" :model="formLabelAlign" class="f" ref="formLabelAlign" :key="indexIndexKey">
                  <div class="fl f1_class1">
                    <div class="topbutton">
                      <el-form-item label="负债总额:" prop="totalCurrentLiability">
                        <el-input class="mc" v-model="formLabelAlign.totalCurrentLiability" @input="regNumber7"
                          @blur="formLabelAlign.totalCurrentLiability = filter(formLabelAlign.totalCurrentLiability)" @focus="inputMoneyFocus7(formLabelAlign.totalCurrentLiability)" maxlength="12">
                        </el-input>
                      </el-form-item>
                    </div>
                    <el-form-item label="流动负债合计:" prop="otherPayables">
                      <el-input class="mc" v-model="formLabelAlign.otherPayables" @input="regNumber8" @blur="formLabelAlign.otherPayables = filter(formLabelAlign.otherPayables)"
                        @focus="inputMoneyFocus8(formLabelAlign.otherPayables)" maxlength="12"></el-input>
                    </el-form-item>
                    <div class="topbutton2">
                      <el-form-item label="非流动负债合计:" prop="depositReceived">
                        <el-input class="mc" v-model="formLabelAlign.depositReceived" @input="regNumber9" @blur="formLabelAlign.depositReceived = filter(formLabelAlign.depositReceived)"
                          @focus="inputMoneyFocus9(formLabelAlign.depositReceived)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="应付账款:" prop="accountsPayable">
                        <el-input class="mc" v-model="formLabelAlign.accountsPayable" @input="regNumber10" @blur="formLabelAlign.accountsPayable = filter(formLabelAlign.accountsPayable)"
                          @focus="inputMoneyFocus10(formLabelAlign.accountsPayable)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="应付工资:" prop="wagesPayable">
                        <el-input class="mc" v-model="formLabelAlign.wagesPayable" @input="regNumber11" @blur="formLabelAlign.wagesPayable = filter(formLabelAlign.wagesPayable)"
                          @focus="inputMoneyFocus11(formLabelAlign.wagesPayable)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="应付税金:" prop="taxesPayable">
                        <el-input class="mc" v-model="formLabelAlign.taxesPayable" @input="regNumber12" @blur="formLabelAlign.taxesPayable = filter(formLabelAlign.taxesPayable)"
                          @focus="inputMoneyFocus12(formLabelAlign.taxesPayable)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="from3">

                <el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign" class="f3" ref="formLabelAlign">
                  <div class="fl">
                    <div class="topbutton">
                      <el-form-item label="资产总额:" class="mgtop" id="bs" prop="totalAssets">
                        <el-input class="mc3" v-model="formLabelAlign.totalAssets1" @input="regNumber13" @blur="formLabelAlign.totalAssets1 = filter(formLabelAlign.totalAssets1)"
                          @focus="inputMoneyFocus13(formLabelAlign.totalAssets1)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <el-form-item label="非流动资产合计:" id="bs" prop="totalNonCurrentAssets">
                      <el-input class="mc3" v-model="formLabelAlign.totalNonCurrentAssets" @input="regNumber14"
                        @blur="formLabelAlign.totalNonCurrentAssets = filter(formLabelAlign.totalNonCurrentAssets)" @focus="inputMoneyFocus14(formLabelAlign.totalNonCurrentAssets)" maxlength="12">
                      </el-input>
                    </el-form-item>
                    <div class="topbutton2">
                      <el-form-item label="长期投资:" id="bs" prop="permanentInvestment">
                        <el-input class="mc3" v-model="formLabelAlign.permanentInvestment" @input="regNumber15" @blur="formLabelAlign.permanentInvestment = filter(formLabelAlign.permanentInvestment)"
                          @focus="inputMoneyFocus15(formLabelAlign.permanentInvestment)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="固定资产净值:" id="bs" prop="netValueOfFixedAssets">
                        <el-input class="mc3" v-model="formLabelAlign.netValueOfFixedAssets" @input="regNumber16"
                          @blur="formLabelAlign.netValueOfFixedAssets = filter(formLabelAlign.netValueOfFixedAssets)" @focus="inputMoneyFocus16(formLabelAlign.netValueOfFixedAssets)" maxlength="12">
                        </el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="无形资产:" id="bs" prop="intangibleAssets">
                        <el-input class="mc3" v-model="formLabelAlign.intangibleAssets" @input="regNumber17" @blur="formLabelAlign.intangibleAssets = filter(formLabelAlign.intangibleAssets)"
                          @focus="inputMoneyFocus17(formLabelAlign.intangibleAssets)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="长期待摊费用:" id="bs" prop="longTermUnamortizedExpenses">
                        <el-input class="mc3" v-model="formLabelAlign.longTermUnamortizedExpenses" @input="regNumber18"
                          @blur="formLabelAlign.longTermUnamortizedExpenses = filter(formLabelAlign.longTermUnamortizedExpenses)" @focus="inputMoneyFocus18(formLabelAlign.longTermUnamortizedExpenses)"
                          maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="递延税款借项:" id="bs" prop="deferredTaxesDebit">
                        <el-input class="mc3" v-model="formLabelAlign.deferredTaxesDebit" @input="regNumber19" @blur="formLabelAlign.deferredTaxesDebit = filter(formLabelAlign.deferredTaxesDebit)"
                          @focus="inputMoneyFocus19(formLabelAlign.deferredTaxesDebit)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="from3">
                <el-form :label-position="labelPosition" label-width="100px" :model="formLabelAlign" class="f3_2" ref="formLabelAlign">
                  <div class="fl f1_class2">
                    <div class="topbutton">
                      <el-form-item label="负债和股东权益合计:" class="mgtop" prop="constructionInProcess">
                        <el-input class="mc" v-model="formLabelAlign.constructionInProcess" @input="regNumber20"
                          @blur="formLabelAlign.constructionInProcess = filter(formLabelAlign.constructionInProcess)" @focus="inputMoneyFocus20(formLabelAlign.constructionInProcess)" maxlength="12">
                        </el-input>
                      </el-form-item>
                    </div>
                    <el-form-item label="股东权益合计:" prop="totalShareholdersEquity">
                      <el-input class="mc" v-model="formLabelAlign.totalShareholdersEquity" @input="regNumber21"
                        @blur="formLabelAlign.totalShareholdersEquity = filter(formLabelAlign.totalShareholdersEquity)" @focus="inputMoneyFocus21(formLabelAlign.totalShareholdersEquity)"
                        onkeyup='this.value=this.value.replace(/\D/gi,"")' oninput="this.value=this.value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')" maxlength="12"></el-input>
                    </el-form-item>
                    <div class="topbutton2">
                      <el-form-item label="实收资本:" prop="paiclUpCapital">
                        <el-input class="mc" v-model="formLabelAlign.paiclUpCapital" @input="regNumber22" @blur="formLabelAlign.paiclUpCapital = filter(formLabelAlign.paiclUpCapital)"
                          @focus="inputMoneyFocus22(formLabelAlign.paiclUpCapital)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="资本公积:" prop="capitalReserve">
                        <el-input class="mc" v-model="formLabelAlign.capitalReserve" @input="regNumber23" @blur="formLabelAlign.capitalReserve = filter(formLabelAlign.capitalReserve)"
                          @focus="inputMoneyFocus23(formLabelAlign.capitalReserve)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="盈余公积:" prop="earnedSurplus">
                        <el-input class="mc" v-model="formLabelAlign.earnedSurplus" @input="regNumber24" @blur="formLabelAlign.earnedSurplus = filter(formLabelAlign.earnedSurplus)"
                          @focus="inputMoneyFocus24(formLabelAlign.earnedSurplus)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="未分配利润:" prop="undistributedProfit">
                        <el-input class="mc" v-model="formLabelAlign.undistributedProfit" @input="regNumber25" @blur="formLabelAlign.undistributedProfit = filter(formLabelAlign.undistributedProfit)"
                          @focus="inputMoneyFocus25(formLabelAlign.undistributedProfit)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                    <div class="topbutton2">
                      <el-form-item label="资产总额:" prop="totalAssets">
                        <el-input class="mc" v-model="formLabelAlign.totalAssets" @input="regNumber26" @blur="formLabelAlign.totalAssets = filter(formLabelAlign.totalAssets)"
                          @focus="inputMoneyFocus26(formLabelAlign.totalAssets)" maxlength="12"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>

              </div>
            </div>
            <div class="dk3_2">
              <el-form :label-position="labelPosition" label-width="100px" :model="formLabelAlign" class="f2" ref="formLabelAlign">
                <div class="fl f1_class3">
                  <div class="topbutton">
                    <el-form-item label="主营业收入:" prop="mainBusinessIncome">
                      <el-input class="mc" v-model="formLabelAlign.mainBusinessIncome" @input="regNumber27" @blur="formLabelAlign.mainBusinessIncome = filter(formLabelAlign.mainBusinessIncome)"
                        @focus="inputMoneyFocus27(formLabelAlign.mainBusinessIncome)" maxlength="12"></el-input>
                    </el-form-item>
                  </div>
                  <el-form-item label="主营业成本:" prop="mainBusinessCost">
                    <el-input class="mc" v-model="formLabelAlign.mainBusinessCost" @input="regNumber28" @blur="formLabelAlign.mainBusinessCost = filter(formLabelAlign.mainBusinessCost)"
                      @focus="inputMoneyFocus28(formLabelAlign.mainBusinessCost)" maxlength="12"></el-input>
                  </el-form-item>
                  <div class="topbutton2">
                    <el-form-item label="主营业税金:" prop="mainBusinessTax">
                      <el-input class="mc" v-model="formLabelAlign.mainBusinessTax" @input="regNumber29" @blur="formLabelAlign.mainBusinessTax = filter(formLabelAlign.mainBusinessTax)"
                        @focus="inputMoneyFocus29(formLabelAlign.mainBusinessTax)" maxlength="12"></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="主营业利润:" prop="mainBusinessProfits">
                      <el-input class="mc" v-model="formLabelAlign.mainBusinessProfits" @input="regNumber30" @blur="formLabelAlign.mainBusinessProfits = filter(formLabelAlign.mainBusinessProfits)"
                        @focus="inputMoneyFocus30(formLabelAlign.mainBusinessProfits)" maxlength="12"></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="营业费用:" prop="operatingExpense">
                      <el-input class="mc" v-model="formLabelAlign.operatingExpense" @input="regNumber31" @blur="formLabelAlign.operatingExpense = filter(formLabelAlign.operatingExpense)"
                        @focus="inputMoneyFocus31(formLabelAlign.operatingExpense)" maxlength="12"></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="管理费用:" prop="administrationExpense">
                      <el-input class="mc" v-model="formLabelAlign.administrationExpense" @input="regNumber32"
                        @blur="formLabelAlign.administrationExpense = filter(formLabelAlign.administrationExpense)" @focus="inputMoneyFocus32(formLabelAlign.administrationExpense)" maxlength="12">
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="财务费用:" prop="financialExpense">
                      <el-input class="mc" v-model="formLabelAlign.financialExpense" @input="regNumber33" @blur="formLabelAlign.financialExpense = filter(formLabelAlign.financialExpense)"
                        @focus="inputMoneyFocus33(formLabelAlign.financialExpense)" maxlength="12"></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="营业利润:" prop="operatingProfit">
                      <el-input class="mc" v-model="formLabelAlign.operatingProfit" @input="regNumber34" @blur="formLabelAlign.operatingProfit = filter(formLabelAlign.operatingProfit)"
                        @focus="inputMoneyFocus34(formLabelAlign.operatingProfit)" maxlength="12"></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="投资损益:" prop="profitAndLossOnInvestments">
                      <el-input class="mc" v-model="formLabelAlign.profitAndLossOnInvestments" @input="regNumber35"
                        @blur="formLabelAlign.profitAndLossOnInvestments = filter(formLabelAlign.profitAndLossOnInvestments)" @focus="inputMoneyFocus35(formLabelAlign.profitAndLossOnInvestments)"
                        maxlength="12">
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="营业外收入:" prop="nonbusinessIncome">
                      <el-input class="mc" v-model="formLabelAlign.nonbusinessIncome" @input="regNumber36" @blur="formLabelAlign.nonbusinessIncome = filter(formLabelAlign.nonbusinessIncome)"
                        @focus="inputMoneyFocus36(formLabelAlign.nonbusinessIncome)" maxlength="12"></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="利润总额:" prop="totalProfit">
                      <el-input class="mc" v-model="formLabelAlign.totalProfit" @input="regNumber37" @blur="formLabelAlign.totalProfit = filter(formLabelAlign.totalProfit)"
                        @focus="inputMoneyFocus37(formLabelAlign.totalProfit)" maxlength="12"></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="所得税:" prop="incomeTax">
                      <el-input class="mc" v-model="formLabelAlign.incomeTax" @input="regNumber38" @blur="formLabelAlign.incomeTax = filter(formLabelAlign.incomeTax)"
                        @focus="inputMoneyFocus38(formLabelAlign.incomeTax)" maxlength="12"></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="净利润:" prop="retainedProfits">
                      <el-input class="mc" v-model="formLabelAlign.retainedProfits" @input="regNumber39" @blur="formLabelAlign.retainedProfits = filter(formLabelAlign.retainedProfits)"
                        @focus="inputMoneyFocus39(formLabelAlign.retainedProfits)" maxlength="12"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
          <div class="dk4">
            <div class="dk4_1">
              <el-form class="f4" :model="formLabelAlign" label-width="110px">
                <div class="f4_1">
                  <div class="ygrs" id="gs">
                    <el-form-item label="员工人数:">
                      <el-input class="inputk" v-model="formLabelAlign.numberOfEmployees" @input="regNumber40" maxlength="8"></el-input>
                    </el-form-item>
                  </div>
                  <div id="gs" style="float:left">
                    <el-form-item label="核心员工人数:" prop="numberOfCoreEmployees">
                      <el-input class="inputk" v-model="formLabelAlign.numberOfCoreEmployees" @input="regNumber41" maxlength="8">
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="ygrs2" id="gs">
                    <el-form-item label="主营业务:" :inline="true" style="flex-wrap:wrap" class="in" prop="mainBusinesses">
                      <el-input class="inputk" v-model="mainBusinesses1" maxlength="100"></el-input>
                      <el-input class="inputk2" v-model="mainBusinesses2" maxlength="100"></el-input>
                      <el-input class="inputk2" v-model="mainBusinesses3" maxlength="100"></el-input>
                    </el-form-item>
                  </div>
                  <div class="ygrs2" id="gs">
                    <el-form-item label="国际业务描述:" class="in" prop="internationalBusinessDescriptions">
                      <el-select placeholder="" class="select_class inputk" v-model="internationalBusinessDescriptions" @change="gjywmsx()">
                        <el-option v-for="item in options" :key="item.label" :label="item.value" :value="item.label">
                        </el-option>
                      </el-select>
                      <el-input class="inputk2" v-model="internationalBusinessDescriptions1" @blur="internationalInput()" maxlength="100"></el-input>
                      <el-input class="inputk3" v-model="internationalBusinessDescriptions2" @blur="internationalInput()" maxlength="100"></el-input>
                    </el-form-item>
                  </div>
                  <div class="ygrs3" id="gs">
                    <el-form-item label="核心竞争力:" class="in" prop="coreCompetencess">
                      <el-checkbox-group v-model="formLabelAlign4.coreCompetencess">
                        <el-checkbox v-for="item in coreCompetencesList" :key="item.index" :label="item.label">{{item.value}}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>
                  <div id="gs">
                    <el-form-item label="业务展望:" class="in" prop="theBusinessOutlook">
                      <el-input class="inputk" v-model="theBusinessOutlook" maxlength="100" @input="ywzw"></el-input>
                      <!-- <el-select placeholder="" class="select_class inputk" v-model="theBusinessOutlook" @change="ywzw">
                        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select> -->
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
          <div class="dk5">
            <div class="dk5_1">
              <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" class="f5" ref="formLabelAlign" :key="indexIndexIndex">
                <div class="c">
                  <div class="qscwjzl">请上传文件资料</div>
                  <el-upload class="upload-demoscwj" action="fakeaction" list-type="picture" :http-request="handleUploadFile" v-model="formLabelAlign.files" ref="uploads">
                    <div class="djsc2">
                      <el-button size="small" type="primary" round class="djsc">上传文件</el-button>
                    </div>
                    <div slot="tip" class="yscwj">已上传文件</div>
                  </el-upload>
                  <ul class="ul  el-upload-list">
                    <!-- <div></div> -->
                    <li class="flex_center_box el-upload-list__item" v-for="(item,index) in fileName" :key="index">
                      <el-tooltip style="width:auto" class="item" effect="light" :content="item" placement="top" popper-class="popper_upload_list_class">
                        <span class="file_name el-upload-list__item-name">{{item}}</span>
                      </el-tooltip>
                      <i class="el-icon-close" @click="delFileItem(index)"></i>
                    </li>
                  </ul>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </el-card>
    </div>

  </div>
</template>
<script>
import { creditInfo, operating, riskInfo, ratingInfo, getUserInfo, creditOptimizationList, creditOptimization, uploads, indexTool } from "@/api/supplier.js";
import { mixins1 } from "@/mixins/index";
export default {
  mixins: [mixins1],
  data() {
    return {
      indexIndexIndex: 1,
      indexIndexKey: '1',
      formLabelAlign4: {
        coreCompetencess: []
      },
      options: [

        {
          value: '否',
          label: '0'
        },
        {
          value: '是',
          label: '1'
        }
      ],
      options1: [
        {
          value: '人民币',
          label: '0'
        },
      ],
      options2: [
        {
          value: '0',
          label: '吃不准'
        }
      ],
      hxjzl: [],
      fileList: [],
      labelPosition: 'right',
      checkedCities: [],
      address_ex: null,
      serviceDiv: 1,
      Alldate: {},
      Alldate1: {},
      Alldate2: {},
      Alldate3: {},
      img: require('@/assets/img/system/supplier/workicon1.png'),
      img1: require('@/assets/img/system/supplier/wicon1.png'),
      img2: require('@/assets/img/system/supplier/wicon2.png'),
      img3: require('@/assets/img/system/supplier/wicon3.png'),
      // img4: require('@/assets/img/system/supplier/company1.png'),
      e_3Aecharts: {
      },
      echartRisk1: null,
      echartRisk2: null,
      echartRisk3: null,
      echartRisk4: null,
      percent: {},
      cnCompany: {},
      fontSize: '',
      echartWidth: '',
      minSize: '',
      raduisWidth: '',
      addressInfo: '',
      gjywms: "",
      gjywms2: "",
      formLabelAlign: {
        years: '',
        totalCurrentAssets: "",
      },
      formLabelAlign2: {
        id: "",
        totalCurrentAssets: "",
        monetaryCapital: "",
        accountsReceivable: "",
        advancePayment: "",
        stock: "",
        unamortizedExpense: "",
        totalNonCurrentAssets: "",
        permanentInvestment: "",
        netValueOfFixedAssets: "",
        constructionInProcess: "",
        intangibleAssets: "",
        longTermUnamortizedExpenses: "",
        deferredTaxesDebit: "",
        numberOfEmployees: "",
        numberOfCoreEmployees: "",
        totalCurrentLiability: "",
        accountsPayable: "",
        depositReceived: "",
        wagesPayable: "",
        taxesPayable: "",
        otherPayables: "",
        totalShareholdersEquity: "",
        paiclUpCapital: "",
        capitalReserve: "",
        earnedSurplus: "",
        undistributedProfit: "",
        totalAssets: "",
        mainBusinessIncome: "",
        mainBusinessCost: "",
        mainBusinessTax: "",
        mainBusinessProfits: "",
        otherBusinessProfits: "",
        operatingExpense: "",
        administrationExpense: "",
        financialExpense: "",
        operatingProfit: "",
        profitAndLossOnInvestments: "",
        nonbusinessIncome: "",
        totalProfit: "",
        incomeTax: "",
        retainedProfits: "",
        mainBusiness: "",
        internationalBusinessDescription: "",
        // coreCompetence:"",
        theBusinessOutlook: "",
        files: "",
        cnCompany: "",
        years: "",
        fileName: [],
        fileUrl: [],
        mainBusinesses: [],
        internationalBusinessDescriptions: [],
        coreCompetences: [],
      },
      year: "",
      theBusinessOutlook: "吃不准",
      internationalBusinessDescriptions: "",
      internationalBusinessDescriptionsL: [],
      internationalBusinessDescriptions1: '',
      internationalBusinessDescriptions2: '',
      mainBusinesses1: '',
      mainBusinesses2: '',
      mainBusinesses3: '',
      mainBusinessesL: [],
      upFileList: [],
      fileName: [],
      fileUrl: [],
      coreCompetencesList: [
        {
          label: '1',
          value: '专利'
        },
        {
          label: '2',
          value: '商标'
        },
        {
          label: '3',
          value: '著作权'
        },
        {
          label: '4',
          value: '软件著作权'
        },
        {
          label: '5',
          value: '高新'
        },
      ],
      formLabelAligns: {
        years: ''
      },
      exceptArrivalClass: false,
      companyName: '',
      contractPhone: '',
      emails: '',
      webstate: '',
      revenueRecord: '',
      socialRecord: '',
      governanceStructure: '',
      management: '',
      shareholder: '',
      legalRepresentative: '',
      personnel_ex: null,
      management_ex: null,
      itemVlaue1: null,
      itemVlaue2: null,
      itemVlaue3: null,
      itemVlaue4: null,
      marketValue: "",
      industy_marketValue: '',
      industy_growth: '',
      growth: '',
      highCompanyCount: '',
      highCompany: '',
      business: '',
      businessValue: '',
      itemValueA: null,
      itemValueB: null,
      itemValueC: null,
      riskFrom: {},
      itemVlaueD: null,
      itemVlaueE: null,
      itemVlaueF: null,
      itemVlaueG: null,
      itemVlaueH: null,
      itemVlaueJ: null,
      fileNmaeContent: '',
      currentYears: '',
      value: '人民币',
      fromAaaaaa: {},
      logoPhoto: true,
      img4: '',
      cnCompanys: ""
    }
  },
  created() {
    this.resizeFn()
    this.getData()
    this.getData2()
    // this.indexToolList()
  },
  watch: {
    'formLabelAligns.years'(val) {
      this.formLabelAligns.years = '' + val;
    }
  },
  methods: {
    regNumber1(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.totalCurrentAssets = val
    },
    regNumber2(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.monetaryCapital = val
    },
    regNumber3(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.accountsReceivable = val
    },
    regNumber4(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.advancePayment = val
    },
    regNumber5(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.stock = val
    },
    regNumber6(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.unamortizedExpense = val
    },
    regNumber7(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.totalCurrentLiability = val
    },
    regNumber8(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.otherPayables = val
    },
    regNumber9(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.depositReceived = val
    },
    regNumber10(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.accountsPayable = val
    },
    regNumber11(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.wagesPayable = val
    },
    regNumber12(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.taxesPayable = val
    },
    regNumber13(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.totalAssets1 = val
    },
    regNumber14(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.totalNonCurrentAssets = val
    },
    regNumber15(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.permanentInvestment = val
    },
    regNumber16(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.netValueOfFixedAssets = val
    },
    regNumber17(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.intangibleAssets = val
    },
    regNumber18(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.longTermUnamortizedExpenses = val
    },
    regNumber19(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.deferredTaxesDebit = val
    },
    regNumber20(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.constructionInProcess = val
    },
    regNumber21(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.totalShareholdersEquity = val
    },
    regNumber22(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.paiclUpCapital = val
    },
    regNumber23(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.capitalReserve = val
    },
    regNumber24(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.earnedSurplus = val
    },
    regNumber25(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.undistributedProfit = val
    },
    regNumber26(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.totalAssets = val
    },
    regNumber27(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.mainBusinessIncome = val
    },
    regNumber28(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.mainBusinessCost = val
    },
    regNumber29(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.mainBusinessTax = val
    },
    regNumber30(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.mainBusinessProfits = val
    },
    regNumber31(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.operatingExpense = val
    },
    regNumber32(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.administrationExpense = val
    },
    regNumber33(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.financialExpense = val
    },
    regNumber34(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.operatingProfit = val
    },
    regNumber35(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.profitAndLossOnInvestments = val
    },
    regNumber36(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.nonbusinessIncome = val
    },
    regNumber37(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.totalProfit = val
    },
    regNumber38(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.incomeTax = val
    },
    regNumber39(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.retainedProfits = val
    },
    regNumber40(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.numberOfEmployees = val
    },
    regNumber41(val) {
      val = val.replace(/[^\d]/g, '')
      this.formLabelAlign.numberOfCoreEmployees = val
    },
    inputMoneyFocus1(value) {
      this.formLabelAlign.totalCurrentAssets = this.filterOut(value)
    },
    inputMoneyFocus2(value) {
      this.formLabelAlign.monetaryCapital = this.filterOut(value)
    },
    inputMoneyFocus3(value) {
      this.formLabelAlign.accountsReceivable = this.filterOut(value)
    },
    inputMoneyFocus4(value) {
      this.formLabelAlign.advancePayment = this.filterOut(value)
    },
    inputMoneyFocus5(value) {
      this.formLabelAlign.stock = this.filterOut(value)
    },
    inputMoneyFocus6(value) {
      this.formLabelAlign.unamortizedExpense = this.filterOut(value)
    },
    inputMoneyFocus7(value) {
      this.formLabelAlign.totalCurrentLiability = this.filterOut(value)
    },
    inputMoneyFocus8(value) {
      this.formLabelAlign.otherPayables = this.filterOut(value)
    },
    inputMoneyFocus9(value) {
      this.formLabelAlign.depositReceived = this.filterOut(value)
    },
    inputMoneyFocus10(value) {
      this.formLabelAlign.accountsPayable = this.filterOut(value)
    },
    inputMoneyFocus11(value) {
      this.formLabelAlign.wagesPayable = this.filterOut(value)
    },
    inputMoneyFocus12(value) {
      this.formLabelAlign.taxesPayable = this.filterOut(value)
    },
    inputMoneyFocus13(value) {
      this.formLabelAlign.totalAssets1 = this.filterOut(value)
    },
    inputMoneyFocus14(value) {
      this.formLabelAlign.totalNonCurrentAssets = this.filterOut(value)
    },
    inputMoneyFocus15(value) {
      this.formLabelAlign.permanentInvestment = this.filterOut(value)
    },
    inputMoneyFocus16(value) {
      this.formLabelAlign.netValueOfFixedAssets = this.filterOut(value)
    },
    inputMoneyFocus17(value) {
      this.formLabelAlign.intangibleAssets = this.filterOut(value)
    },
    inputMoneyFocus18(value) {
      this.formLabelAlign.longTermUnamortizedExpenses = this.filterOut(value)
    },
    inputMoneyFocus19(value) {
      this.formLabelAlign.deferredTaxesDebit = this.filterOut(value)
    },
    inputMoneyFocus20(value) {
      this.formLabelAlign.constructionInProcess = this.filterOut(value)
    },
    inputMoneyFocus21(value) {
      this.formLabelAlign.totalShareholdersEquity = this.filterOut(value)
    },
    inputMoneyFocus22(value) {
      this.formLabelAlign.paiclUpCapital = this.filterOut(value)
    },
    inputMoneyFocus23(value) {
      this.formLabelAlign.capitalReserve = this.filterOut(value)
    },
    inputMoneyFocus24(value) {
      this.formLabelAlign.earnedSurplus = this.filterOut(value)
    },
    inputMoneyFocus25(value) {
      this.formLabelAlign.undistributedProfit = this.filterOut(value)
    },
    inputMoneyFocus26(value) {
      this.formLabelAlign.totalAssets = this.filterOut(value)
    },
    inputMoneyFocus27(value) {
      this.formLabelAlign.mainBusinessIncome = this.filterOut(value)
    },
    inputMoneyFocus28(value) {
      this.formLabelAlign.mainBusinessCost = this.filterOut(value)
    },
    inputMoneyFocus29(value) {
      this.formLabelAlign.mainBusinessTax = this.filterOut(value)
    },
    inputMoneyFocus30(value) {
      this.formLabelAlign.mainBusinessProfits = this.filterOut(value)
    },
    inputMoneyFocus31(value) {
      this.formLabelAlign.operatingExpense = this.filterOut(value)
    },
    inputMoneyFocus32(value) {
      this.formLabelAlign.administrationExpense = this.filterOut(value)
    },
    inputMoneyFocus33(value) {
      this.formLabelAlign.financialExpense = this.filterOut(value)
    },
    inputMoneyFocus34(value) {
      this.formLabelAlign.operatingProfit = this.filterOut(value)
    },
    inputMoneyFocus35(value) {
      this.formLabelAlign.profitAndLossOnInvestments = this.filterOut(value)
    },
    inputMoneyFocus36(value) {
      this.formLabelAlign.nonbusinessIncome = this.filterOut(value)
    },
    inputMoneyFocus37(value) {
      this.formLabelAlign.totalProfit = this.filterOut(value)
    },
    inputMoneyFocus38(value) {
      this.formLabelAlign.incomeTax = this.filterOut(value)
    },
    inputMoneyFocus39(value) {
      this.formLabelAlign.retainedProfits = this.filterOut(value)
    },
    inputMoneyFocus40(value) {
      this.formLabelAlign.numberOfEmployees = this.filterOut(value)
    },
    inputMoneyFocus41(value) {
      this.formLabelAlign.numberOfCoreEmployees = this.filterOut(value)
    },
    // 
    // async indexToolList(){
    //   let res = await indexTool('entName='+'商安信（上海）企业发展股份有限公司')
    //   if (res.data.regUnusualAbnormality) {
    //     this.address_ex = Math.ceil(res.data.regUnusualAbnormality / 20)
    //   }
    //   if (res.data.personUnusualAbnormality) {
    //     this.personnel_ex = Math.ceil(res.data.personUnusualAbnormality / 20)
    //   }
    //   if (res.data.operateAbnormality) {
    //     this.management_ex = Math.ceil(res.data.operateAbnormality / 20)
    //   }
    //   if (res.data.mortgagePledge) {
    //     this.itemVlaue1 = Math.ceil(res.data.mortgagePledge / 20)
    //   }
    //   if (res.data.salesAbnormality) {
    //     this.itemVlaue2 = Math.ceil(res.data.salesAbnormality / 20)
    //   }
    //   if (res.data.customerConcentration) {
    //     this.itemVlaue3 = Math.ceil(res.data.customerConcentration / 20)
    //   }
    //   if (res.data.solvency) {
    //     this.itemVlaue4 = Math.ceil(res.data.solvency / 20)
    //   }
    //   if (res.data.marketShare) {
    //     this.marketValue = Number(res.data.marketShare).toFixed(2)
    //   }
    //   if (res.data.marketShareRank) {
    //     this.industy_marketValue = Math.ceil(Number(res.data.marketShareRank / res.data.industryCompanyNum * 100))
    //   }
    //   if (res.data.growthRate) {
    //     this.growth = Number(res.data.growthRate).toFixed(2)
    //   }
    //   if (res.data.marketShareRank) {
    //     this.industy_marketValue = Math.ceil(Number(res.data.marketShareRank / res.data.industryCompanyNum * 100))
    //   }
    //   if (res.data.hightechEnterprise === 100) {
    //     this.highCompany = '是'
    //   } else if (res.data.hightechEnterprise === 0) {
    //     this.highCompany = '否'
    //   } else {
    //     return ''
    //   }
    //   if (res.data.totaloperatereveRank) {
    //     this.businessValue = Math.ceil(Number(res.data.totaloperatereveRank / res.data.industryCompanyNum * 100))
    //   }
    //   if (res.data.salesQuality) {
    //     this.itemValueA = Math.ceil(res.data.salesQuality / 20)
    //   }
    //   if (res.data.researchAndInnovation) {
    //     this.itemValueB = Math.ceil(res.data.researchAndInnovation / 20)
    //   }
    //   if (res.data.rankInTheField) {
    //     this.itemValueC = Math.ceil(res.data.rankInTheField / 20)
    //   }
    //   if (res.data.illegalAndUntrustworthy) {
    //     this.itemVlaueD = Math.ceil(res.data.illegalAndUntrustworthy / 20)
    //   }
    //   if (res.data.highSpendingLimits) {
    //     this.itemVlaueE = Math.ceil(res.data.highSpendingLimits / 20)
    //   }
    //   if (res.data.dishonestPerson) {
    //     this.itemVlaueF = Math.ceil(res.data.dishonestPerson / 20)
    //   }
    //   if (res.data.administrativePenalties) {
    //     this.itemVlaueG = Math.ceil(res.data.administrativePenalties / 20)
    //   }
    //   if (res.data.bargainingPower) {
    //     this.itemValueH = Math.ceil(res.data.bargainingPower / 20)
    //   }
    //   if (res.data.customerQuality) {
    //     this.itemValueJ = Math.ceil(res.data.customerQuality / 20)
    //   }
    //   this.riskFrom = res.data
    //   this.business = res.data.taking
    //   this.highCompanyCount = res.data.patent
    //   this.contractPhone = res.data.phone
    //   this.emails = res.data.email
    //   this.webstate = res.data.website
    //   this.revenueRecord = res.data.salesRecord
    //   this.socialRecord = res.data.paymentRecords
    //   this.governanceStructure = res.data.governanceStructure
    //   this.management = res.data.abnormalOperation
    //   this.shareholder = res.data.shareholdersAbnormal
    //   this.legalRepresentative = res.data.legalRepresentative
    // },
    exceptArrivalChange2() {
      if (this.formLabelAligns.years) {
        this.exceptArrivalClass = true
      }
      else {
        this.exceptArrivalClass = false
      }
    },
    exceptArrivalChange1() {
      if (this.formLabelAligns.years) {
        this.exceptArrivalClass = true
      }
      else {
        this.exceptArrivalClass = false
      }
    },
    internationalInput() {
      if (!this.internationalBusinessDescriptions1 && !this.internationalBusinessDescriptions2) {
        this.internationalBusinessDescriptions = '否'
      } else {
        this.internationalBusinessDescriptions = '是'
      }
    },
    async dateYearChange() {
      let datas = localStorage.getItem('cnCompany')
      this.cnCompanys = datas
      let res = await creditOptimizationList('year=' + this.formLabelAligns.years + '&entName=' + this.cnCompanys)
      if (res.data != null) {
        this.formLabelAlign = res.data
        this.formLabelAligns.years = res.data.years
        if (res.data.internationalBusinessDescriptions != null) {
          this.internationalBusinessDescriptions1 = res.data.internationalBusinessDescriptions[1]
          this.internationalBusinessDescriptions2 = res.data.internationalBusinessDescriptions[2]
        } else {
          this.internationalBusinessDescriptions1 = ''
          this.internationalBusinessDescriptions2 = ''
        }
        this.fileName = res.data.fileName
        this.fileUrl = res.data.fileUrl
        if (res.data.internationalBusinessDescriptions) {
          this.internationalBusinessDescriptions = "是"
        } else {
          this.internationalBusinessDescriptions = "否"
        }

        if (res.data.mainBusinesses != null) {

          this.mainBusinesses1 = res.data.mainBusinesses[0]
          this.mainBusinesses2 = res.data.mainBusinesses[1]
          this.mainBusinesses3 = res.data.mainBusinesses[2]
        } else {
          this.mainBusinesses1 = ''
          this.mainBusinesses2 = ''
          this.mainBusinesses3 = ''
        }
        if (res.data.coreCompetences != null) {
          this.formLabelAlign4.coreCompetencess = res.data.coreCompetences
        } else {
          this.formLabelAlign4.coreCompetencess = []
        }
        this.aaaaa()
      }
      else {
        this.deleteData()
        this.formLabelAlign4.coreCompetencess = []
        this.mainBusinesses1 = ''
        this.mainBusinesses2 = ''
        this.mainBusinesses3 = ''
        this.internationalBusinessDescriptions1 = ''
        this.internationalBusinessDescriptions2 = ''
        this.internationalBusinessDescriptions = ''
        this.theBusinessOutlook = ''
        this.fileName = []
      }
    },
    async gjywmsx(val) {
      let datas = localStorage.getItem('cnCompany')
      this.cnCompanys = datas
      let res = await creditOptimizationList('year=' + this.formLabelAligns.years + '&entName=' + this.cnCompanys)
      if (this.internationalBusinessDescriptions == 1 && res.data.internationalBusinessDescriptions != null) {
        this.internationalBusinessDescriptions1 = res.data.internationalBusinessDescriptions[1]
        this.internationalBusinessDescriptions2 = res.data.internationalBusinessDescriptions[2]
      } else {
        this.internationalBusinessDescriptions1 = ''
        this.internationalBusinessDescriptions2 = ''
      }

    },
    ywzw(val) {
      console.log(val, 1177);
    },
    xuanzhong(val) {
      console.log(val, 1097);
      console.log(this.formLabelAlign.coreCompetences);
    },
    handleUploadFile(params) {
      console.log(params, 1180)
      // if (!this.formLabelAlign.files) {
      const file = params.file
      let form = new FormData();
      form.append("files", file);
      uploads(form).then(res => {
        // console.log(res);
        this.fileName.push(params.file.name)
        // console.log(this.formLabelAlign.fileName,1189);
        // console.log(res, 'res12')
        this.fileUrl.push(res[0]);
      })
      // }
    },
    async getData2() {
      let nowDate = new Date()
      this.formLabelAligns.years = nowDate.getFullYear()
      let datas = localStorage.getItem('cnCompany')
      this.cnCompanys = datas
      let data = await creditOptimizationList('entName=' + this.cnCompanys + '&year=' + this.formLabelAligns.years + '&flag=' + 1)
      if (data.data != null) {
        this.formLabelAlign = data.data
        this.formLabelAligns.years = data.data.years
        if (data.data.internationalBusinessDescriptions != null) {
          this.internationalBusinessDescriptions1 = data.data.internationalBusinessDescriptions[1]
          this.internationalBusinessDescriptions2 = data.data.internationalBusinessDescriptions[2]

        } else {
          this.internationalBusinessDescriptions1 = ''
          this.formLabelAlign.internationalBusinessDescriptions2 = ''
        }
        if (data.data.internationalBusinessDescriptions.length > 0) {
          this.internationalBusinessDescriptions = "是"
        } else {
          this.internationalBusinessDescriptions = ""
        }
        //  if(data.data.internationalBusinessDescriptions){
        //    this.internationalBusinessDescriptions="是"
        //  }else{
        //    this.internationalBusinessDescriptions="否"
        //  }
        if (data.data.mainBusinesses != null) {

          this.mainBusinesses1 = data.data.mainBusinesses[0]
          this.mainBusinesses2 = data.data.mainBusinesses[1]
          this.mainBusinesses3 = data.data.mainBusinesses[2]
        } else {
          this.mainBusinesses1 = ''
          this.mainBusinesses2 = ''
          this.mainBusinesses3 = ''
        }
        if (this.options2 != []) {

          this.theBusinessOutlook = ''
        }
        this.fileName = data.data.fileName
        this.fileUrl = data.data.fileUrl
        if (data.data.coreCompetences != null) {
          this.formLabelAlign4.coreCompetencess = data.data.coreCompetences
        }
        else if (data.data.coreCompetences == []) {
          this.formLabelAlign4.coreCompetencess = data.data.coreCompetences
        }
        else {
          this.formLabelAlign4.coreCompetencess = []
        }
        console.log(this.formLabelAlign.files)
        this.formLabelAlign.totalCurrentAssets = this.filter(this.formLabelAlign.totalCurrentAssets)
        this.formLabelAlign.monetaryCapital = this.filter(this.formLabelAlign.monetaryCapital)
        this.formLabelAlign.accountsReceivable = this.filter(this.formLabelAlign.accountsReceivable)
        this.formLabelAlign.advancePayment = this.filter(this.formLabelAlign.advancePayment)
        this.formLabelAlign.stock = this.filter(this.formLabelAlign.stock)
        this.formLabelAlign.unamortizedExpense = this.filter(this.formLabelAlign.unamortizedExpense)
        this.formLabelAlign.totalCurrentLiability = this.filter(this.formLabelAlign.totalCurrentLiability)
        this.formLabelAlign.otherPayables = this.filter(this.formLabelAlign.otherPayables)
        this.formLabelAlign.depositReceived = this.filter(this.formLabelAlign.depositReceived)
        this.formLabelAlign.accountsPayable = this.filter(this.formLabelAlign.accountsPayable)
        this.formLabelAlign.wagesPayable = this.filter(this.formLabelAlign.wagesPayable)
        this.formLabelAlign.taxesPayable = this.filter(this.formLabelAlign.taxesPayable)
        this.formLabelAlign.totalAssets = this.filter(this.formLabelAlign.totalAssets)
        this.formLabelAlign.totalNonCurrentAssets = this.filter(this.formLabelAlign.totalNonCurrentAssets)
        this.formLabelAlign.permanentInvestment = this.filter(this.formLabelAlign.permanentInvestment)
        this.formLabelAlign.netValueOfFixedAssets = this.filter(this.formLabelAlign.netValueOfFixedAssets)
        this.formLabelAlign.intangibleAssets = this.filter(this.formLabelAlign.intangibleAssets)
        this.formLabelAlign.longTermUnamortizedExpenses = this.filter(this.formLabelAlign.longTermUnamortizedExpenses)
        this.formLabelAlign.deferredTaxesDebit = this.filter(this.formLabelAlign.deferredTaxesDebit)
        this.formLabelAlign.constructionInProcess = this.filter(this.formLabelAlign.constructionInProcess)
        this.formLabelAlign.totalShareholdersEquity = this.filter(this.formLabelAlign.totalShareholdersEquity)
        this.formLabelAlign.paiclUpCapital = this.filter(this.formLabelAlign.paiclUpCapital)
        this.formLabelAlign.capitalReserve = this.filter(this.formLabelAlign.capitalReserve)
        this.formLabelAlign.earnedSurplus = this.filter(this.formLabelAlign.earnedSurplus)
        this.formLabelAlign.undistributedProfit = this.filter(this.formLabelAlign.undistributedProfit)
        this.formLabelAlign.totalAssets = this.filter(this.formLabelAlign.totalAssets)
        this.formLabelAlign.mainBusinessIncome = this.filter(this.formLabelAlign.mainBusinessIncome)
        this.formLabelAlign.mainBusinessCost = this.filter(this.formLabelAlign.mainBusinessCost)
        this.formLabelAlign.mainBusinessTax = this.filter(this.formLabelAlign.mainBusinessTax)
        this.formLabelAlign.mainBusinessProfits = this.filter(this.formLabelAlign.mainBusinessProfits)
        this.formLabelAlign.operatingExpense = this.filter(this.formLabelAlign.operatingExpense)
        this.formLabelAlign.administrationExpense = this.filter(this.formLabelAlign.administrationExpense)
        this.formLabelAlign.financialExpense = this.filter(this.formLabelAlign.financialExpense)
        this.formLabelAlign.operatingProfit = this.filter(this.formLabelAlign.operatingProfit)
        this.formLabelAlign.profitAndLossOnInvestments = this.filter(this.formLabelAlign.profitAndLossOnInvestments)
        this.formLabelAlign.nonbusinessIncome = this.filter(this.formLabelAlign.nonbusinessIncome)
        this.formLabelAlign.totalProfit = this.filter(this.formLabelAlign.totalProfit)
        this.formLabelAlign.incomeTax = this.filter(this.formLabelAlign.incomeTax)
        this.formLabelAlign.retainedProfits = this.filter(this.formLabelAlign.retainedProfits)
      }
      else {
        //  this.formLabelAlign.internationalBusinessDescriptions[0] = ''
        //  this.formLabelAlign.internationalBusinessDescriptions[1] = ''

        // this.fileName = []
        // this.formLabelAlign.mainBusinesses = []
        // this.formLabelAlign.internationalBusinessDescriptions = []
        // this.internationalBusinessDescriptions = ''
        // this.theBusinessOutlook = ''
        // this.formLabelAlign.coreCompetences = []
      }
    },
    deleteData() {
      this.formLabelAlign.numberOfEmployees = ''
      this.formLabelAlign.numberOfCoreEmployees = ''
      this.internationalBusinessDescriptions1 = ''
      this.internationalBusinessDescriptions2 = ''
      this.mainBusinesses1 = ''
      this.mainBusinesses2 = ''
      this.mainBusinesses3 = ''
      this.formLabelAlign.totalCurrentAssets = ''
      this.formLabelAlign.monetaryCapital = ''
      this.formLabelAlign.accountsReceivable = ''
      this.formLabelAlign.advancePayment = ''
      this.formLabelAlign.stock = ''
      this.formLabelAlign.unamortizedExpense = ''
      this.formLabelAlign.totalCurrentLiability = ''
      this.formLabelAlign.otherPayables = ''
      this.formLabelAlign.depositReceived = ''
      this.formLabelAlign.accountsPayable = ''
      this.formLabelAlign.wagesPayable = ''
      this.formLabelAlign.taxesPayable = ''
      this.formLabelAlign.totalAssets = ''
      this.formLabelAlign.totalNonCurrentAssets = ''
      this.formLabelAlign.permanentInvestment = ''
      this.formLabelAlign.netValueOfFixedAssets = ''
      this.formLabelAlign.intangibleAssets = ''
      this.formLabelAlign.longTermUnamortizedExpenses = ''
      this.formLabelAlign.deferredTaxesDebit = ''
      this.formLabelAlign.constructionInProcess = ''
      this.formLabelAlign.totalShareholdersEquity = ''
      this.formLabelAlign.paiclUpCapital = ''
      this.formLabelAlign.capitalReserve = ''
      this.formLabelAlign.earnedSurplus = ''
      this.formLabelAlign.undistributedProfit = ''
      this.formLabelAlign.totalAssets = ''
      this.formLabelAlign.mainBusinessIncome = ''
      this.formLabelAlign.mainBusinessCost = ''
      this.formLabelAlign.mainBusinessTax = ''
      this.formLabelAlign.mainBusinessProfits = ''
      this.formLabelAlign.operatingExpense = ''
      this.formLabelAlign.administrationExpense = ''
      this.formLabelAlign.financialExpense = ''
      this.formLabelAlign.operatingProfit = ''
      this.formLabelAlign.profitAndLossOnInvestments = ''
      this.formLabelAlign.nonbusinessIncome = ''
      this.formLabelAlign.totalProfit = ''
      this.formLabelAlign.incomeTax = ''
      this.formLabelAlign.retainedProfits = ''
      // })
    },
    async getData3() {
      let datas = localStorage.getItem('cnCompany')
      this.cnCompanys = datas
      let data = await creditOptimizationList('entName=' + this.cnCompanys + '&year=' + this.formLabelAligns.years)
      this.formLabelAlign = data.data
      this.formLabelAligns.years = data.data.years
      if (data.data.internationalBusinessDescriptions.length > 0) {
        this.internationalBusinessDescriptions = "是"
      } else {
        this.internationalBusinessDescriptions = ""
      }
      this.internationalBusinessDescriptions1 = data.data.internationalBusinessDescriptions[1]
      this.internationalBusinessDescriptions2 = data.data.internationalBusinessDescriptions[2]
      this.mainBusinesses1 = data.data.mainBusinesses[0]
      this.mainBusinesses2 = data.data.mainBusinesses[1]
      this.mainBusinesses3 = data.data.mainBusinesses[2]
      this.fileName = data.data.fileName
      this.fileUrl = data.data.fileUrl
      if (data.data.coreCompetences != null) {
        this.formLabelAlign4.coreCompetencess = data.data.coreCompetences
      }
      else if (data.data.coreCompetences == []) {
        this.formLabelAlign4.coreCompetencess = data.data.coreCompetences
      }
      else {
        this.formLabelAlign4.coreCompetencess = []
      }
      this.formLabelAlign.totalCurrentAssets = this.filter(this.formLabelAlign.totalCurrentAssets)
      this.formLabelAlign.monetaryCapital = this.filter(this.formLabelAlign.monetaryCapital)
      this.formLabelAlign.accountsReceivable = this.filter(this.formLabelAlign.accountsReceivable)
      this.formLabelAlign.advancePayment = this.filter(this.formLabelAlign.advancePayment)
      this.formLabelAlign.stock = this.filter(this.formLabelAlign.stock)
      this.formLabelAlign.unamortizedExpense = this.filter(this.formLabelAlign.unamortizedExpense)
      this.formLabelAlign.totalCurrentLiability = this.filter(this.formLabelAlign.totalCurrentLiability)
      this.formLabelAlign.otherPayables = this.filter(this.formLabelAlign.otherPayables)
      this.formLabelAlign.depositReceived = this.filter(this.formLabelAlign.depositReceived)
      this.formLabelAlign.accountsPayable = this.filter(this.formLabelAlign.accountsPayable)
      this.formLabelAlign.wagesPayable = this.filter(this.formLabelAlign.wagesPayable)
      this.formLabelAlign.taxesPayable = this.filter(this.formLabelAlign.taxesPayable)
      this.formLabelAlign.totalAssets = this.filter(this.formLabelAlign.totalAssets)
      this.formLabelAlign.totalNonCurrentAssets = this.filter(this.formLabelAlign.totalNonCurrentAssets)
      this.formLabelAlign.permanentInvestment = this.filter(this.formLabelAlign.permanentInvestment)
      this.formLabelAlign.netValueOfFixedAssets = this.filter(this.formLabelAlign.netValueOfFixedAssets)
      this.formLabelAlign.intangibleAssets = this.filter(this.formLabelAlign.intangibleAssets)
      this.formLabelAlign.longTermUnamortizedExpenses = this.filter(this.formLabelAlign.longTermUnamortizedExpenses)
      this.formLabelAlign.deferredTaxesDebit = this.filter(this.formLabelAlign.deferredTaxesDebit)
      this.formLabelAlign.constructionInProcess = this.filter(this.formLabelAlign.constructionInProcess)
      this.formLabelAlign.totalShareholdersEquity = this.filter(this.formLabelAlign.totalShareholdersEquity)
      this.formLabelAlign.paiclUpCapital = this.filter(this.formLabelAlign.paiclUpCapital)
      this.formLabelAlign.capitalReserve = this.filter(this.formLabelAlign.capitalReserve)
      this.formLabelAlign.earnedSurplus = this.filter(this.formLabelAlign.earnedSurplus)
      this.formLabelAlign.undistributedProfit = this.filter(this.formLabelAlign.undistributedProfit)
      this.formLabelAlign.totalAssets = this.filter(this.formLabelAlign.totalAssets)
      this.formLabelAlign.mainBusinessIncome = this.filter(this.formLabelAlign.mainBusinessIncome)
      this.formLabelAlign.mainBusinessCost = this.filter(this.formLabelAlign.mainBusinessCost)
      this.formLabelAlign.mainBusinessTax = this.filter(this.formLabelAlign.mainBusinessTax)
      this.formLabelAlign.mainBusinessProfits = this.filter(this.formLabelAlign.mainBusinessProfits)
      this.formLabelAlign.operatingExpense = this.filter(this.formLabelAlign.operatingExpense)
      this.formLabelAlign.administrationExpense = this.filter(this.formLabelAlign.administrationExpense)
      this.formLabelAlign.financialExpense = this.filter(this.formLabelAlign.financialExpense)
      this.formLabelAlign.operatingProfit = this.filter(this.formLabelAlign.operatingProfit)
      this.formLabelAlign.profitAndLossOnInvestments = this.filter(this.formLabelAlign.profitAndLossOnInvestments)
      this.formLabelAlign.nonbusinessIncome = this.filter(this.formLabelAlign.nonbusinessIncome)
      this.formLabelAlign.totalProfit = this.filter(this.formLabelAlign.totalProfit)
      this.formLabelAlign.incomeTax = this.filter(this.formLabelAlign.incomeTax)
      this.formLabelAlign.retainedProfits = this.filter(this.formLabelAlign.retainedProfits)
    },
    aaaaa() {
      this.formLabelAlign.totalCurrentAssets = this.filter(this.formLabelAlign.totalCurrentAssets)
      this.formLabelAlign.monetaryCapital = this.filter(this.formLabelAlign.monetaryCapital)
      this.formLabelAlign.accountsReceivable = this.filter(this.formLabelAlign.accountsReceivable)
      this.formLabelAlign.advancePayment = this.filter(this.formLabelAlign.advancePayment)
      this.formLabelAlign.stock = this.filter(this.formLabelAlign.stock)
      this.formLabelAlign.unamortizedExpense = this.filter(this.formLabelAlign.unamortizedExpense)
      this.formLabelAlign.totalCurrentLiability = this.filter(this.formLabelAlign.totalCurrentLiability)
      this.formLabelAlign.otherPayables = this.filter(this.formLabelAlign.otherPayables)
      this.formLabelAlign.depositReceived = this.filter(this.formLabelAlign.depositReceived)
      this.formLabelAlign.accountsPayable = this.filter(this.formLabelAlign.accountsPayable)
      this.formLabelAlign.wagesPayable = this.filter(this.formLabelAlign.wagesPayable)
      this.formLabelAlign.taxesPayable = this.filter(this.formLabelAlign.taxesPayable)
      this.formLabelAlign.totalAssets = this.filter(this.formLabelAlign.totalAssets)
      this.formLabelAlign.totalNonCurrentAssets = this.filter(this.formLabelAlign.totalNonCurrentAssets)
      this.formLabelAlign.permanentInvestment = this.filter(this.formLabelAlign.permanentInvestment)
      this.formLabelAlign.netValueOfFixedAssets = this.filter(this.formLabelAlign.netValueOfFixedAssets)
      this.formLabelAlign.intangibleAssets = this.filter(this.formLabelAlign.intangibleAssets)
      this.formLabelAlign.longTermUnamortizedExpenses = this.filter(this.formLabelAlign.longTermUnamortizedExpenses)
      this.formLabelAlign.deferredTaxesDebit = this.filter(this.formLabelAlign.deferredTaxesDebit)
      this.formLabelAlign.constructionInProcess = this.filter(this.formLabelAlign.constructionInProcess)
      this.formLabelAlign.totalShareholdersEquity = this.filter(this.formLabelAlign.totalShareholdersEquity)
      this.formLabelAlign.paiclUpCapital = this.filter(this.formLabelAlign.paiclUpCapital)
      this.formLabelAlign.capitalReserve = this.filter(this.formLabelAlign.capitalReserve)
      this.formLabelAlign.earnedSurplus = this.filter(this.formLabelAlign.earnedSurplus)
      this.formLabelAlign.undistributedProfit = this.filter(this.formLabelAlign.undistributedProfit)
      this.formLabelAlign.totalAssets = this.filter(this.formLabelAlign.totalAssets)
      this.formLabelAlign.mainBusinessIncome = this.filter(this.formLabelAlign.mainBusinessIncome)
      this.formLabelAlign.mainBusinessCost = this.filter(this.formLabelAlign.mainBusinessCost)
      this.formLabelAlign.mainBusinessTax = this.filter(this.formLabelAlign.mainBusinessTax)
      this.formLabelAlign.mainBusinessProfits = this.filter(this.formLabelAlign.mainBusinessProfits)
      this.formLabelAlign.operatingExpense = this.filter(this.formLabelAlign.operatingExpense)
      this.formLabelAlign.administrationExpense = this.filter(this.formLabelAlign.administrationExpense)
      this.formLabelAlign.financialExpense = this.filter(this.formLabelAlign.financialExpense)
      this.formLabelAlign.operatingProfit = this.filter(this.formLabelAlign.operatingProfit)
      this.formLabelAlign.profitAndLossOnInvestments = this.filter(this.formLabelAlign.profitAndLossOnInvestments)
      this.formLabelAlign.nonbusinessIncome = this.filter(this.formLabelAlign.nonbusinessIncome)
      this.formLabelAlign.totalProfit = this.filter(this.formLabelAlign.totalProfit)
      this.formLabelAlign.incomeTax = this.filter(this.formLabelAlign.incomeTax)
      this.formLabelAlign.retainedProfits = this.filter(this.formLabelAlign.retainedProfits)
    },
    //删除上传文件
    delFileItem(item) {
      this.fileName.splice(item, 1)
      this.fileUrl.splice(item, 1)
    },
    async getData() {
      this.serviceDiv = 1
      this.cnCompany.entname = localStorage.getItem('cnCompany');
      // this.cnCompany.entname = '上海喜临门家具有限公司';
      let data = await getUserInfo('financingName=' + this.cnCompany.entname)
      this.percent = data.data
      this.img4 = data.data.headPort
      if (this.percent.address == '') {
        this.percent.address = '--'
      }
      if (data.data.city == null) {
        this.addressInfo = '--'
      } else {
        this.addressInfo = data.data.city + ',' + data.data.province + ',' + data.data.country
      }
      if (data.data.headPort == null) {
        this.logoPhoto = false
      } else {
        this.logoPhoto = true
      }
      data = await creditInfo(this.cnCompany)
      this.Alldate = data.data
      this.Alldate1 = data.data.operatingResponse
      // data = await operating(this.cnCompany)
      // this.Alldate1 = data.data
      data = await riskInfo(this.cnCompany)
      this.Alldate2 = data.data
      data = await ratingInfo(this.cnCompany)
      this.e_3Aecharts = data.data
      console.log(this.e_3Aecharts)
      this.$nextTick(function () {
        this.riskEchart1(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth)
        this.riskEchart2(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth)
        this.riskEchart3(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth)
      })
    },
    async tijiao() {
      let datas = localStorage.getItem('cnCompany')
      this.cnCompanys = datas
      let query = {
        entName: this.cnCompanys,
        year: this.formLabelAligns.years
      }
      this.formLabelAlign2 = this.formLabelAlign
      this.formLabelAlign2.coreCompetences = this.formLabelAlign4.coreCompetencess
      this.formLabelAlign2.totalCurrentAssets = this.filterOuts(this.formLabelAlign.totalCurrentAssets)
      this.formLabelAlign2.monetaryCapital = this.filterOuts(this.formLabelAlign.monetaryCapital)
      this.formLabelAlign2.accountsReceivable = this.filterOuts(this.formLabelAlign.accountsReceivable)
      this.formLabelAlign2.advancePayment = this.filterOuts(this.formLabelAlign.advancePayment)
      this.formLabelAlign2.stock = this.filterOuts(this.formLabelAlign.stock)
      this.formLabelAlign2.unamortizedExpense = this.filterOuts(this.formLabelAlign.unamortizedExpense)
      this.formLabelAlign2.totalCurrentLiability = this.filterOuts(this.formLabelAlign.totalCurrentLiability)
      this.formLabelAlign2.otherPayables = this.filterOuts(this.formLabelAlign.otherPayables)
      this.formLabelAlign2.depositReceived = this.filterOuts(this.formLabelAlign.depositReceived)
      this.formLabelAlign2.accountsPayable = this.filterOuts(this.formLabelAlign.accountsPayable)
      this.formLabelAlign2.wagesPayable = this.filterOuts(this.formLabelAlign.wagesPayable)
      this.formLabelAlign2.taxesPayable = this.filterOuts(this.formLabelAlign.taxesPayable)
      this.formLabelAlign2.totalAssets = this.filter(this.formLabelAlign.totalAssets)
      this.formLabelAlign2.totalNonCurrentAssets = this.filterOuts(this.formLabelAlign.totalNonCurrentAssets)
      this.formLabelAlign2.permanentInvestment = this.filterOuts(this.formLabelAlign.permanentInvestment)
      this.formLabelAlign2.netValueOfFixedAssets = this.filterOuts(this.formLabelAlign.netValueOfFixedAssets)
      this.formLabelAlign2.intangibleAssets = this.filterOuts(this.formLabelAlign.intangibleAssets)
      this.formLabelAlign2.longTermUnamortizedExpenses = this.filterOuts(this.formLabelAlign.longTermUnamortizedExpenses)
      this.formLabelAlign2.deferredTaxesDebit = this.filterOuts(this.formLabelAlign.deferredTaxesDebit)
      this.formLabelAlign2.constructionInProcess = this.filter(this.formLabelAlign.constructionInProcess)
      this.formLabelAlign2.totalShareholdersEquity = this.filterOuts(this.formLabelAlign.totalShareholdersEquity)
      this.formLabelAlign2.paiclUpCapital = this.filterOuts(this.formLabelAlign.paiclUpCapital)
      this.formLabelAlign2.capitalReserve = this.filterOuts(this.formLabelAlign.capitalReserve)
      this.formLabelAlign2.earnedSurplus = this.filterOuts(this.formLabelAlign.earnedSurplus)
      this.formLabelAlign2.undistributedProfit = this.filterOuts(this.formLabelAlign.undistributedProfit)
      this.formLabelAlign2.totalAssets = this.filterOuts(this.formLabelAlign.totalAssets)
      this.formLabelAlign2.mainBusinessIncome = this.filterOuts(this.formLabelAlign.mainBusinessIncome)
      this.formLabelAlign2.mainBusinessCost = this.filterOuts(this.formLabelAlign.mainBusinessCost)
      this.formLabelAlign2.mainBusinessTax = this.filterOuts(this.formLabelAlign.mainBusinessTax)
      this.formLabelAlign2.mainBusinessProfits = this.filterOuts(this.formLabelAlign.mainBusinessProfits)
      this.formLabelAlign2.operatingExpense = this.filterOuts(this.formLabelAlign.operatingExpense)
      this.formLabelAlign2.administrationExpense = this.filterOuts(this.formLabelAlign.administrationExpense)
      this.formLabelAlign2.financialExpense = this.filterOuts(this.formLabelAlign.financialExpense)
      this.formLabelAlign2.operatingProfit = this.filterOuts(this.formLabelAlign.operatingProfit)
      this.formLabelAlign2.profitAndLossOnInvestments = this.filterOuts(this.formLabelAlign.profitAndLossOnInvestments)
      this.formLabelAlign2.nonbusinessIncome = this.filterOuts(this.formLabelAlign.nonbusinessIncome)
      this.formLabelAlign2.totalProfit = this.filterOuts(this.formLabelAlign.totalProfit)
      this.formLabelAlign2.incomeTax = this.filterOuts(this.formLabelAlign.incomeTax)
      this.formLabelAlign2.retainedProfits = this.filterOuts(this.formLabelAlign.retainedProfits)
      this.internationalBusinessDescriptionsL.push(this.internationalBusinessDescriptions, this.internationalBusinessDescriptions1, this.internationalBusinessDescriptions2)
      this.formLabelAlign2.internationalBusinessDescriptions = this.internationalBusinessDescriptionsL
      this.mainBusinessesL.push(this.mainBusinesses1, this.mainBusinesses2, this.mainBusinesses3)
      this.formLabelAlign2.mainBusinesses = this.mainBusinessesL
      this.formLabelAlign2.fileName = this.fileName
      this.formLabelAlign2.fileUrl = this.fileUrl
      this.formLabelAlign2.theBusinessOutlook = this.theBusinessOutlook
      let res = await creditOptimization(query, this.formLabelAlign2)
      this.getData3()
      this.internationalBusinessDescriptionsL = []
      this.mainBusinessesL = []
      if (res.code == 200) {
        this.$message({
          message: '您修改的信息已提交成功!',
          type: 'success'
        });
      } else {
        this.$message({
          message: '您修改的信息提交失败!',
          type: 'error'
        });
      }
    },
    RiskPrediction() {
      this.serviceDiv = 3
      this.$nextTick(function () {
        let j = document.getElementsByClassName('risk_prediction')
        for (let i = 0; i < j.length; i++) {
          j[i].getElementsByClassName('risk_prediction_left')[0].style.height = j[i].getElementsByClassName('risk_prediction_right')[0].clientHeight + 'px'
        }
        this.riskEchart4()
        this.riskEchart5()
        this.riskEchart6()
        this.riskEchart7()
        this.riskEchart8()
        this.riskEchart9()
      })
    },
    RiskPrediction2() {
      this.serviceDiv = 2
      this.exceptArrivalChange2()
    },
    riskEchart1(fontSize, echartWidth, minSize, raduisWidth, minRaduisWidth) {
      let data = { A: 1, B: 2, C: 3, D: 4, F: 5 }
      let score = 0
      if (this.e_3Aecharts.dataQuality == '--') {
        score = 'F'
      } else {
        score = this.e_3Aecharts.dataQuality
      }
      if (this.echartRisk1 && this.echartRisk1 != null) {
        this.echartRisk1.dispose()
      }
      this.echartRisk1 = this.$echarts.init(this.$refs.risk1)
      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 4,
            max: 1,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              formatter:  this.e_3Aecharts.dataQuality=='--'  ? this.Alldate.dataQuality : this.e_3Aecharts.dataQuality,
              fontSize: fontSize,
              color: '#142746',
              offsetCenter: ['0', '-32%']
            },
            title: {
              fontSize: minSize,
              color: '#142746',
              offsetCenter: ['0', '-3%']
            },
            axisLine: {
              lineStyle: {
                width: echartWidth,
                color: [
                  [0.20, '#EB2323'],
                  [0.40, '#F58F23'],
                  [0.60, '#FEDE29'],
                  [0.80, '#88C647'],
                  [1, '#148547']
                ]
              },
              roundCap: true
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '10%',
              width: raduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: 'auto'
              }
            },
            data: [
              {
                value: data[score],
                name: '商业评级分'
              }
            ]
          },
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 4,
            max: 1,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              show: false
            },
            title: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '20%',
              width: minRaduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: '#fff'
              }
            },
            data: [
              {
                value: data[score]
              }
            ]
          }
        ]
      }
      this.echartRisk1.setOption(option, true)
      this.echartRisk1.resize()
    },
    riskEchart2(fontSize, echartWidth, minSize, raduisWidth, minRaduisWidth) {
      let score = 0
      if (this.e_3Aecharts.aaaRating != '0') {
        score = this.e_3Aecharts.aaaRating.substr(2)
      }
      if (this.echartRisk1 && this.echartRisk11 != null) {
        this.echartRisk1.dispose()
      }
      this.echartRisk1 = this.$echarts.init(this.$refs.risk2)
      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 19,
            max: 0,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              formatter: score > 0 ? '3A' + score : '--',
              fontSize: fontSize,
              color: '#142746',
              offsetCenter: ['0', '-32%']
            },
            title: {
              fontSize: minSize,
              color: '#142746',
              offsetCenter: ['0', '-3%']
            },
            axisLine: {
              lineStyle: {
                width: echartWidth,
                color: [
                  [0.16, '#EB2323'],
                  [0.33, '#F58F23'],
                  [0.50, '#FEDE29'],
                  [0.66, '#88C647'],
                  [0.83, '#02B152'],
                  [1, '#148547']
                ]
              },
              roundCap: true
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '10%',
              width: raduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: 'auto'
              }
            },
            data: [
              {
                value: score,
                name: '3ARating评级'
              }
            ]
          },
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 19,
            max: 0,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              show: false
            },
            title: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '20%',
              width: minRaduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: '#fff'
              }
            },
            data: [
              {
                value: score
              }
            ]
          }
        ]
      }
      this.echartRisk1.setOption(option, true)
      this.echartRisk1.resize()
    },
    riskEchart3(fontSize, echartWidth, minSize, raduisWidth, minRaduisWidth) {
      let score = 0
      let cc = { '0': 'AAA', '30': 'BBB', '40': 'BB-', '45': 'BBB-', '60': 'ccc' }
      if (this.e_3Aecharts.aaaRating != '0') {
        score = this.e_3Aecharts.aaaRating.substr(2)
      }
      if (this.echartRisk3 && this.echartRisk3 != null) {
        this.echartRisk3.dispose()
      }
      this.echartRisk3 = this.$echarts.init(this.$refs.risk3)
      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 19,
            max: 0,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              formatter: this.e_3Aecharts.thridRating,
              fontSize: fontSize,
              color: '#142746',
              offsetCenter: ['0', '-32%']
            },
            title: {
              fontSize: minSize,
              color: '#142746',
              offsetCenter: ['0', '-3%']
            },
            axisLine: {
              lineStyle: {
                width: echartWidth,
                color: [
                  [0.14, '#700608'],
                  [0.28, '#EB2323'],
                  [0.42, '#F58F23'],
                  [0.57, '#FEDE29'],
                  [0.71, '#88C647'],
                  [0.85, '#02B152'],
                  [1, '#148547']
                ]
              },
              roundCap: true
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '10%',
              width: raduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: 'auto'
              }
            },
            data: [
              {
                value: score,
                name: '第三方评级'
              }
            ]
          },
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 19,
            max: 0,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              show: false
            },
            title: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '20%',
              width: minRaduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: '#fff'
              }
            },
            data: [
              {
                value: score
              }
            ]
          }
        ]
      }
      this.echartRisk3.setOption(option, true)
      this.echartRisk3.resize()
    },
    async riskEchart4() {
      let res = await indexTool('entName=' + '商安信（上海）企业发展股份有限公司')
      let score = res.data.emptyShellIndex
      score = score.toFixed(1)
      this.echartRisk4 = this.$echarts.init(this.$refs.risk4)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    async riskEchart5() {
      let res = await indexTool('entName=' + '商安信（上海）企业发展股份有限公司')
      let score = res.data.thunderIndex
      this.echartRisk4 = this.$echarts.init(this.$refs.risk5)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    async riskEchart6() {
      let res = await indexTool('entName=' + '商安信（上海）企业发展股份有限公司')
      let score = res.data.competitivenessIndex
      this.echartRisk4 = this.$echarts.init(this.$refs.risk6)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    async riskEchart7() {
      let res = await indexTool('entName=' + '商安信（上海）企业发展股份有限公司')
      let score = res.data.riskControlIndex
      this.echartRisk4 = this.$echarts.init(this.$refs.risk7)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    async riskEchart8() {
      let res = await indexTool('entName=' + '商安信（上海）企业发展股份有限公司')
      let score = res.data.supplyChainIndex
      this.echartRisk4 = this.$echarts.init(this.$refs.risk8)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    async riskEchart9() {
      let res = await indexTool('entName=' + '商安信（上海）企业发展股份有限公司')
      let score = res.data.esgIndex
      score = score.toFixed(1)
      this.echartRisk4 = this.$echarts.init(this.$refs.risk9)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    riskEchartAll(fontSize, echartWidth, minSize, raduisWidth, minRaduisWidth, score) {

      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 0,
            max: 100,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              formatter: score,
              fontSize: fontSize,
              color: '#142746',
              offsetCenter: ['0', '-32%']
            },
            title: {
              fontSize: minSize,
              color: '#142746',
              offsetCenter: ['0', '-3%']
            },
            axisLine: {
              lineStyle: {
                width: echartWidth,
                color: [
                  [0.50, '#88C647'],
                  [0.75, '#F58F23'],
                  [1, '#EB2323'],
                ]
              },
              roundCap: true
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '10%',
              width: raduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: 'auto'
              }
            },
            data: [
              {
                value: score,
                name: '指数(分)'
              }
            ]
          },
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 0,
            max: 100,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              show: false
            },
            title: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '20%',
              width: minRaduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: '#fff'
              }
            },
            data: [
              {
                value: score
              }
            ]
          }
        ]
      }

      this.echartRisk4.setOption(option, true)
      this.echartRisk4.resize()
    },
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.fontSize = 51.9
        this.echartWidth = 8
        this.minSize = 11.9
        this.raduisWidth = 16
        this.minRaduisWidth = 7
      } else (
        this.fontSize = 34.1,
        this.echartWidth = 5,
        this.minSize = 8.9,
        this.raduisWidth = 10,
        this.minRaduisWidth = 4
      )
    },
  }
}
</script>
<style scoped>
  /* ::v-deep .el-icon-star-on{
                                                                                                                                                                                                                                                                                                                                              color: #FB6476 !important;
                                                                                                                                                                                                                                                                                                                                            } */
  ::v-deep .dk3 .el-form-item__content {
    display: flex;
  }
  ::v-deep .year .el-form-item__content {
    display: flex;
  }
  ::v-deep .f1_class2 .el-form-item__label {
    width: 148px !important;
  }
  ::v-deep .f1_class3 .el-form-item__label {
    width: 85px !important;
  }
  /* ::v-deep .f1_class2 .el-input{
                                                                                                                                                                                                                                                                                                                                              width: 260px !important;
                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                            ::v-deep .f1_class2 .el-input__inner{
                                                                                                                                                                                                                                                                                                                                              width: 260px !important;
                                                                                                                                                                                                                                                                                                                                            } */
  ::v-deep .f1_class1 .el-form-item__label {
    width: 116px !important;
  }
  .year_form_item >>> .el-form-item__label {
    width: 36px !important;
  }
  ::v-deep .exceptArrivalClass .el-date-editor:hover .el-input__prefix {
    display: none;
  }
  ::v-deep
    .exceptArrivalClass
    .is-success
    .el-date-editor:hover
    .el-input__prefix {
    display: none;
  }
  ::v-deep .upload-demoscwj .el-upload-list {
    display: none;
  }
  ::v-deep .el-checkbox__inner {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  ::v-deep .el-checkbox__inner::after {
    width: 7px;
    height: 12px;
    top: 2px;
  }
  /* 风险预测 */
  .risk_prediction {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    padding: 10px 30px 30px 30px;
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(12, 79, 71, 0.15);
    border-radius: 10px;
  }
  .ztidax {
    font-size: 14px;
  }
  .risk_prediction_tit {
    height: 42px;
    line-height: 42px;
    font-size: 18px;
  }

  .risk_prediction_left {
    /* height: 500px; */
    background: #f7fafc;
    border-radius: 12px;
    width: 19%;
    margin-right: 2%;
  }
  .risk_prediction_left_tit {
    height: 18px;
    margin-top: 30px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  .risk_prediction_right {
    height: 100%;
    background: #f7fafc;
    border-radius: 12px;
    width: 79%;
  }
  .risk_prediction_right_div {
    padding: 30px 60px 0 60px;
    min-height: 278px;
  }
  .risk_prediction_right_div_top {
    margin-bottom: 60px;
  }
  .risk_prediction_right_div_bottom {
    margin-bottom: 30px;
  }
  .risk_prediction_con_left {
  }
  .risk_prediction_con_tit {
    width: 400px;
    height: 38px;
    background: #ffffff;
    border-radius: 19px;
  }
  .risk_prediction_con_con {
    line-height: 18px;
    padding: 0 20px;
    margin-top: 20px;
    font-size: 14px;
  }
  .risk_prediction_rate {
    margin-left: 20px;
  }
  .iconfont.icon-xingxing,
  .iconfont.icon-xingxing1 {
    color: #fb6476;
  }
  .risk_prediction_center {
    width: 110px;
    text-align: right;
  }
  .risk_prediction_con_con div {
    min-width: 80px;
  }
  .risk_prediction_con_con div:first-child {
    text-align: left;
  }
  .risk_prediction_con_con div:last-child {
    text-align: right;
  }
  /* 风险预测止 */
  .work_business_company_div {
    line-height: 86px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .companyDiv {
    padding-left: 12px;
    padding-right: 15px;
  }
  .corporateRateLeftEcharts {
    width: 230px;
    height: 120px;
  }
  .work_top {
    padding-bottom: 30px;
  }
  .work_top_div {
    width: 86px;
    height: 86px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
    margin-right: 20px;
  }
  .work_top_div img {
    position: relative;
    top: 40%;
    left: 40%;
  }
  .over2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .float_right {
    float: right;
  }
  .icon-gudongxinxi {
    font-size: 30px !important;
  }
  .icon-xiaopangchezhengxinguanli {
    font-size: 24px !important;
  }
  tr td {
    min-width: 90px;
    max-width: 90px;
    height: 38px;
    padding-top: 10px;
    font-size: 14px;
  }
  tr td:nth-child(1) {
    margin-right: 10px;
    color: #54657f;
    text-align: left;
  }
  tr td:nth-child(2) {
    text-align: right;
  }
  tr td:nth-child(3) {
    text-align: right;
  }
  .corporate_image_tit {
    margin-bottom: 20px;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
  }
  .corporate_image {
    width: 1500px;
    height: 376px;
    padding-top: 64px;
    padding-bottom: 50px;
    overflow-x: scroll;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .corporate_image_div {
    width: auto;
  }
  .corporate_image_div table {
    overflow: hidden;
  }
  .corporate_image_icon {
    position: absolute;
    /* position: relative; */
    width: 60px;
    height: 60px;
    left: 50%;
    top: -30px;
    border-radius: 50%;
    transform: translateX(-50%);
    background-color: #339c9b;
    box-shadow: 0px 8px 14px 0px rgba(47, 160, 159, 0.23);
  }
  .corporate_image_edit {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 4px;
    height: 18px;
  }
  .corporate_image_edit div {
    position: relative;
    width: 4px;
    height: 4px;
    margin-bottom: 2px;
    border-radius: 50%;
    background: #54657f;
  }
  .corporate_image_icon .iconfont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 21px;
  }
  .corporate_image li {
    position: relative;
    flex-shrink: 0;
    width: 225px;
    height: 262px;
    margin-left: 12px;
    margin-right: 12px;
    padding: 50px 20px 0 20px;
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0px 10px 14px 0px rgba(12, 79, 71, 0.16);
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
  }
  .corporate_image li:hover {
    width: 400px;
  }
  .corporate_image li:nth-child(1):hover {
    margin-left: 183px;
  }
  .corporate_image li:nth-child(6):hover {
    margin-right: 183px;
  }
  .corporate_image li:hover tr td {
    min-width: 117px;
    max-width: 120px;
  }
  .corporate_image li:hover .table_line2 tr td:nth-child(1) {
    min-width: 100px;
    max-width: 100px;
  }
  .corporate_image li:hover .table_line2 tr td:nth-child(2) {
    min-width: 250px;
    max-width: 250px;
  }
  .corporate_image li:hover .corporate_image_edit {
    display: none;
  }
  .corporate_image li .corporate_image_tr_td3 {
    display: none;
  }
  .corporate_image li:hover .corporate_image_tr_td3 {
    display: table-cell;
  }
  .up {
    color: #339c9b;
  }
  .down {
    color: #fb6476;
  }
  .corporate_image li:nth-child(1) {
    margin-left: 7px;
  }
  .corporate_image li:last-child {
    margin-right: 7px;
  }
  .work_chart_detail_color.color1 {
    background-color: #68bf50;
  }
  .work_chart_detail_score.color1 {
    color: #68bf50;
  }
  .work_chart_detail_color.color2 {
    background-color: #f6c009;
  }
  .work_chart_detail_score.color2 {
    color: #f6c009;
  }
  .work_chart_detail_color.color3 {
    background-color: #f8e71c;
  }
  .work_chart_detail_score.color3 {
    color: #f8e71c;
  }
  .work_chart_detail_color.color4 {
    background-color: #ff6c64;
  }
  .work_chart_detail_score.color4 {
    color: #ff6c64;
  }
  .work_chart_detail_color.color5 {
    background-color: #d0021b;
  }
  .work_chart_detail_score.color5 {
    color: #d0021b;
  }
  .work_business {
    width: 650px;
    height: 410px;
    padding: 40px 40px 0 40px;
    background-size: 100% 100%;
    border-radius: 14px;
    background-image: url('../../../../assets/img/system/supplier/workbg.png');
    box-shadow: 0px 0px 18px 0px rgba(12, 79, 71, 0.19);
    /* background: linear-gradient(180deg, #EFFFFD 0%, #D2F2FF 100%); */
  }
  .work_business_company {
    line-height: 33px;
    font-size: 24px;
    color: #339c9b;
    margin-top: 8px;
    padding-bottom: 18px;
  }
  .work_business_company_en {
    line-height: 18px;
    font-size: 16px;
    color: #54657f;
  }
  .work_business_detail li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 42px; */
    height: 60px;
  }
  .work_business_detail .iconfont {
    font-size: 21px;
    padding-right: 10px;
    color: #54657f;
  }
  .work_business_detail_font {
    font-size: 16px;
    line-height: 22px;
  }
  .work_business_detail_icon {
    width: 21px;
    height: 21px;
    margin-right: 10px;
    background-size: 100% 100%;
  }
  .min_width {
    min-width: 195px;
  }
  .work_business_person {
    margin-top: 50px;
  }
  .work_chart {
    width: 800px;
  }
  .work_chart_item img {
    width: 230px;
    height: 120px;
    background-size: 100% 100%;
  }
  .work_chart_detail {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .work_chart_detail li {
    width: 144px;
    height: 56px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
    background-color: #fff;
  }
  .work_chart_detail_color {
    width: 100%;
    height: 6px;
    margin-bottom: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .work_chart_detail_score {
    line-height: 22px;
    font-size: 16px;
    text-align: center;
  }
  .work_chart_detail_tit {
    line-height: 18px;
    font-size: 12px;
    text-align: center;
    color: #54657f;
  }
  @media screen and (max-width: 1664px) {
    ::v-deep .f1_class1 .el-form-item__label {
      width: 87px !important;
    }
    ::v-deep .f1_class3 .el-form-item__label {
      width: 63px !important;
    }
    ::v-deep .f1_class2 .el-form-item__label {
      width: 111px !important;
    }
    /* ::v-deep .f1_class2 .el-input{
                                                                                                                                                                                                                                                                                                                                                width: 160px !important;
                                                                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                                                                              ::v-deep .f1_class2 .el-input__inner{
                                                                                                                                                                                                                                                                                                                                                width: 160px !important;
                                                                                                                                                                                                                                                                                                                                              } */
    .year_form_item >>> .el-form-item__label {
      width: 27px !important;
    }
    ::v-deep .el-rate__icon {
      font-size: 15px;
    }

    ::v-deep .el-rate__icon.iconfont.icon-xingxing1 {
      color: #fb6476 !important;
    }
    .ztidax {
      font-size: 12px;
    }
    /* 风险预测 */
    .risk_prediction {
      width: 100%;
      height: auto;
      margin-bottom: 27px;
      padding: 7px 20px 20px 20px;
      background: #ffffff;
      box-shadow: 0px 7px 14px 0px rgba(12, 79, 71, 0.15);
      border-radius: 7px;
    }

    .risk_prediction_tit {
      height: 28px;
      line-height: 28px;
      font-size: 12px;
    }

    .risk_prediction_left {
      /* height: 500px; */
      background: #f7fafc;
      border-radius: 8px;
      width: 19%;
      margin-right: 2%;
    }
    .risk_prediction_left_tit {
      height: 12px;
      margin-top: 20px;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
    }
    .risk_prediction_right {
      height: 100%;
      background: #f7fafc;
      border-radius: 8px;
      width: 79%;
    }
    .risk_prediction_right_div {
      padding: 20px 40px 0 40px;
      min-height: 185px;
    }
    .risk_prediction_right_div_top {
      margin-bottom: 40px;
    }
    .risk_prediction_right_div_bottom {
      margin-bottom: 20px;
    }
    .risk_prediction_con_tit {
      width: 277px;
      height: 25px;
      background: #ffffff;
      border-radius: 13px;
    }
    .risk_prediction_con_con {
      line-height: 12px;
      padding: 0 14px;
      margin-top: 14px;
      font-size: 12px;
    }
    .risk_prediction_rate {
      margin-left: 14px;
    }
    .iconfont.icon-xingxing,
    .iconfont.icon-xingxing1 {
      color: #fb6476;
    }
    .risk_prediction_center {
      width: 73px;
      text-align: right;
    }
    .risk_prediction_con_con div {
      min-width: 53px;
    }
    .risk_prediction_con_con div:first-child {
      text-align: left;
    }
    .risk_prediction_con_con div:last-child {
      text-align: right;
    }
    /* 风险预测止 */
    .work_business_company_div {
      line-height: 57px !important;
    }
    .companyDiv {
      padding-left: 8px;
      padding-right: 9px;
    }
    .corporateRateLeftEcharts {
      width: 153px;
      height: 80px;
    }
    .work_top {
      padding-bottom: 20px;
    }
    .work_top_div {
      width: 57px;
      height: 57px;
      background: #ffffff;
      border-radius: 50%;
      box-shadow: 0px 2px 7px 0px rgba(12, 79, 71, 0.16);
      margin-right: 14px;
    }
    .work_top_div img {
      position: relative;
      top: 35%;
      left: 36%;
    }
    .icon-gudongxinxi {
      font-size: 20px !important;
    }
    .icon-xiaopangchezhengxinguanli {
      font-size: 16px !important;
    }
    tr td {
      min-width: 60px;
      max-width: 60px;
      height: 25px;
      padding-top: 7px;
      font-size: 12px;
    }
    tr td:nth-child(1) {
      margin-right: 7px;
      color: #54657f;
      text-align: left;
    }
    .corporate_image_tit {
      margin-bottom: 13px;
      line-height: 13px;
      font-size: 12px;
      text-align: center;
    }
    .corporate_image {
      width: 1000px;
      height: 251px;
      padding-top: 43px;
      padding-bottom: 33px;
      overflow-x: scroll;
      overflow-y: hidden;
      overflow-x: hidden;
    }
    .corporate_image_div {
      width: auto;
    }
    .corporate_image_div table {
      overflow: hidden;
    }
    .corporate_image_icon {
      position: absolute;
      /* position: relative; */
      width: 40px;
      height: 40px;
      left: 50%;
      top: -20px;
      border-radius: 50%;
      transform: translateX(-50%);
      background-color: #339c9b;
      box-shadow: 0px 5px 9px 0px rgba(47, 160, 159, 0.23);
    }
    .corporate_image_edit {
      position: absolute;
      top: 11px;
      right: 11px;
      width: 3px;
      height: 12px;
    }
    .corporate_image_edit div {
      position: relative;
      width: 3px;
      height: 3px;
      margin-bottom: 2px;
      border-radius: 50%;
      background: #54657f;
    }
    .corporate_image_icon .iconfont {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 14px;
    }
    .corporate_image li {
      position: relative;
      flex-shrink: 0;
      width: 150px;
      height: 175px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 33px 13px 0 13px;
      background-color: #fff;
      border-radius: 9px;
      box-shadow: 0px 7px 9px 0px rgba(12, 79, 71, 0.16);
      -webkit-transition: all 0.1s;
      transition: all 0.1s;
    }
    .corporate_image li:hover {
      width: 267px;
    }
    .corporate_image li:nth-child(1):hover {
      margin-left: 122px;
    }
    .corporate_image li:nth-child(6):hover {
      margin-right: 122px;
    }
    .corporate_image li:hover tr td {
      min-width: 78px;
      max-width: 80px;
    }
    .corporate_image li:hover .table_line2 tr td:nth-child(1) {
      min-width: 67px;
      max-width: 67px;
    }
    .corporate_image li:hover .table_line2 tr td:nth-child(2) {
      min-width: 167px;
      max-width: 167px;
    }
    .corporate_image li:hover .corporate_image_edit {
      display: none;
    }
    .corporate_image li .corporate_image_tr_td3 {
      display: none;
    }
    .corporate_image li:hover .corporate_image_tr_td3 {
      display: table-cell;
    }
    .up {
      color: #339c9b;
    }
    .down {
      color: #fb6476;
    }
    .corporate_image li:nth-child(1) {
      margin-left: 5px;
    }
    .corporate_image li:last-child {
      margin-right: 5px;
    }
    .work_chart_detail_color.color1 {
      background-color: #68bf50;
    }
    .work_chart_detail_score.color1 {
      color: #68bf50;
    }
    .work_chart_detail_color.color2 {
      background-color: #f6c009;
    }
    .work_chart_detail_score.color2 {
      color: #f6c009;
    }
    .work_chart_detail_color.color3 {
      background-color: #f8e71c;
    }
    .work_chart_detail_score.color3 {
      color: #f8e71c;
    }
    .work_chart_detail_color.color4 {
      background-color: #ff6c64;
    }
    .work_chart_detail_score.color4 {
      color: #ff6c64;
    }
    .work_chart_detail_color.color5 {
      background-color: #d0021b;
    }
    .work_chart_detail_score.color5 {
      color: #d0021b;
    }
    .work_business {
      width: 433px;
      height: 273px;
      padding: 27px 27px 0 27px;
      background-size: 100% 100%;
      background-image: url('../../../../assets/img/system/supplier/workbg.png');
      box-shadow: 0px 0px 12px 0px rgba(12, 79, 71, 0.19);
      border-radius: 9px;
    }
    .work_business_company {
      line-height: 22px;
      font-size: 16px;
      color: #339c9b;
      margin-top: 5px;
      padding-bottom: 12px;
    }
    .work_business_company_en {
      line-height: 12px;
      font-size: 12px;
      color: #54657f;
    }
    .work_business_detail li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
    }
    .work_business_detail .iconfont {
      font-size: 14px;
      padding-right: 7px;
      color: #54657f;
    }
    .work_business_detail_font {
      font-size: 12px;
      line-height: 15px;
    }
    .work_business_detail_icon {
      width: 14px;
      height: 14px;
      margin-right: 7px;
      background-size: 100% 100%;
    }
    .min_width {
      min-width: 130px;
    }
    .work_business_person {
      margin-top: 33px;
    }
    .work_chart {
      width: 533px;
    }
    .work_chart_item img {
      width: 153px;
      height: 80px;
      background-size: 100% 100%;
    }
    .work_chart_detail {
      margin-top: 27px;
      margin-bottom: 27px;
    }
    .work_chart_detail li {
      width: 96px;
      height: 37px;
      border-radius: 7px;
      box-shadow: 0px 2px 7px 0px rgba(12, 79, 71, 0.16);
      background-color: #fff;
    }
    .work_chart_detail_color {
      width: 100%;
      height: 4px;
      margin-bottom: 3px;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    }
    .work_chart_detail_score {
      line-height: 15px;
      font-size: 12px;
      text-align: center;
    }
    .work_chart_detail_tit {
      line-height: 12px;
      font-size: 12px;
      text-align: center;
      color: #54657f;
    }
  }

  .topbutton {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .date-picker-icon {
    width: 230px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
  }
  .second_title_box {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .second_title1 {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #162747;
  }
  .f3_2 {
    width: 460px;
    height: 460px;
    background: #f7fafc;
    border-radius: 12px;
    margin-right: 30px;
    margin-top: 30px;
  }
  .c {
    margin-left: 20px;
  }
  .ygrs {
    margin-bottom: 20px;
    margin-top: 30px;
    float: left;
  }
  >>> .header_menu_item a {
    color: #339c9b !important;
  }
  .ygrs2 {
    margin-top: 20px;
    float: left;
    width: 1140px;
  }
  .ygrs3 {
    margin-top: 28px;
    margin-bottom: 28px;
    float: left;
  }
  >>> .mc .el-input__inner {
    width: 100%;
    /* width: 308px; */
    height: 40px;
  }
  >>> .inputk .el-input__inner {
    width: 404px;
    height: 40px;
  }
  >>> .inputk2 .el-input__inner {
    width: 404px;
    height: 40px;
  }
  >>> .inputk3 .el-input__inner {
    width: 404px;
    height: 40px;
  }
  >>> .gdpgzx_select .el-input .el-input__inner {
    width: 230px;
    height: 40px;
  }
  >>> .year .date-picker-icon .el-input__inner {
    width: 230px;
    height: 40px;
  }
  .anniu {
    width: 160px;
    height: 40px;
    background-color: #339b9a;
    border-color: #339b9a;
    font-size: 16px;
  }
  .dinput {
    width: 1100px;
    display: flex;
    float: left;
    justify-content: space-between;
    align-items: center;
  }
  .mgtop {
    margin-top: 20px;
  }
  .f {
    width: 460px;
    height: 400px;
    background: #f7fafc;
    border-radius: 12px;
    margin-right: 30px;
    margin-top: 20px;
  }
  .gdpgzx_select {
    width: 230px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
  }
  .inputk {
    width: 404px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    float: left;
  }
  .inputk2 {
    width: 404px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    margin-left: 31px;
    float: left;
  }
  >>> .in .el-form-item__content {
    width: 1340px;
  }
  .inputk3 {
    width: 404px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    margin-left: 31px;
    margin-right: 20px;
  }
  .f2 {
    width: 460px;
    height: 890px;
    background: #f7fafc;
    border-radius: 12px;
    margin-top: 20px;
  }
  .dxk {
    margin-top: 5px;
  }
  .f3 {
    width: 460px;
    height: 460px;
    background: #f7fafc;
    border-radius: 12px;
    margin-top: 30px;
    margin-right: 30px;
  }
  .f4 {
    width: 1440px;
    height: 400px;
    background: #f7fafc;
    border-radius: 12px;
    margin-left: 30px;
  }
  .f4_1 {
    margin-left: 20px;
  }
  .fl {
    margin-left: 20px;
    margin-right: 20px;
  }
  .in {
    width: 1000px;
    float: left;
  }

  .tb i {
    font-size: 20px;
    color: #7c969b;
  }
  .topbutton2 {
    margin-top: 20px;
  }
  .dk3_1 {
    width: 980px;
    height: 911px;
    display: flex;
    flex-wrap: wrap;
  }
  >>> .el-form-item__label {
    width: 102px !important;
    height: 24px;
    font-size: 16px;
    padding: 0;
    margin-right: 10px;
  }
  /deep/#bs .el-form-item__label {
    width: 116px !important;
    height: 24px;
    font-size: 16px;
    padding: 0;
    margin-right: 10px;
  }
  >>> .mc3 .el-input__inner {
    width: 294px;
    height: 40px;
  }

  >>> .el-input__prefix {
    margin-left: 200px;
  }
  >>> .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
  }
  .a {
    width: 1500px;
    height: auto;
    /* height: 1748px; */
    padding-bottom: 30px;
    background: #ffffff;
    display: flex;
    border-radius: 10px;
    margin: 0 auto;
  }
  >>> .el-card__body {
    padding: 0;
  }
  >>> .el-form-item {
    padding: 0;
    margin: 0;
    /* width: 450px; */
  }
  >>> .el-input__inner {
    margin-right: 20px;
  }
  .from3 {
    display: flex;
  }

  .dk3 {
    width: 1440px;
    display: flex;
    float: left;
    margin-left: 30px;
    height: 911px;
  }

  .mc {
    width: 100%;
    /* width: 308px; */
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
  }
  .mc2 {
    width: 294px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
  }
  .mc3 {
    width: 294px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
  }
  .dk3_2 {
    display: flex;
    width: 450px;
    height: 920px;
  }
  .dk4 {
    float: left;
    height: 400px;
    margin-top: 30px;
  }
  .dk4_1 {
    height: 380px;
    display: flex;
    float: left;
    align-items: center;
    flex-wrap: wrap;
  }
  .dk5 {
    height: auto;
    /* height: 278px; */
    float: left;
    margin-top: 30px;
  }
  .dk5_1 {
    height: auto;
    /* height: 278px; */
    float: left;
  }
  .qscwjzl {
    margin-top: 30px;
    font-size: 16px;
  }
  .heard {
    margin-top: 30px;
  }
  .gdpgzx {
    float: left;
    margin-left: 30px;
  }
  .year {
    float: left;
    margin-left: 40px;
  }
  .cgbutton {
    float: right;
    margin-right: 30px;
  }
  .djsc2 {
    margin-bottom: 50px;
  }
  >>> .el-upload-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  >>> .el-upload-list__item {
    float: left;
    width: 120px;
    height: 40px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #142746;
    line-height: 35px;
    margin-top: 0px;
    margin-bottom: 20px;
    border-radius: 4px;
    margin-right: 30px;
    /* margin-left: 30px; */
    background: #ffffff;
    cursor: pointer;
  }
  >>> .el-upload {
    width: 161px;
    height: 45px;
    margin-top: 20px;
  }
  >>> .el-upload-list__item:last-child {
    margin: 0;
  }
  /* >>>.el-upload-list__item:not(:last-child){
                                                                                                                                                                                                                                                                                                                                                margin-bottom: 0;
                                                                                                                                                                                                                                                                                                                                            } */
  >>> .el-upload-list__item-name {
    float: left;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #142746;
    line-height: 26px;
    margin-left: 10px;
    margin-top: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .yscwj {
    width: 116px;
    height: 24px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 21px;
    margin-top: 50px;
  }
  >>> #gs .el-form-item {
    padding: 0;
    margin: 0;
    width: 1420px;
  }
  >>> .el-upload-list__item-name [class^='el-icon'] {
    display: none;
  }
  .f5 {
    width: 1440px;
    height: auto;
    padding-bottom: 30px;
    /* height: 278px; */
    background: #f7fafc;
    border-radius: 12px;
    margin-left: 30px;
    float: left;
  }
  .el-checkbox__input.is-checked >>> el-checkbox__inner {
    background-color: red;
    border-color: red;
  }
  /* >>>.el-select .el-input .el-select__caret::before {
                                                                                                                                                                                                                                                                                                                                              content: "\e612";
                                                                                                                                                                                                                                                                                                                                              position: absolute;
                                                                                                                                                                                                                                                                                                                                              width: 100%;
                                                                                                                                                                                                                                                                                                                                              height: 100%;
                                                                                                                                                                                                                                                                                                                                              top: 50%;
                                                                                                                                                                                                                                                                                                                                              left: 50%;
                                                                                                                                                                                                                                                                                                                                              transform: translate(-50%, -50%);
                                                                                                                                                                                                                                                                                                                                            } */

  .djsc {
    width: 160px;
    height: 40px;
    background: #339c9b;
    border-radius: 32px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 21px;
  }
  /* >>>.el-checkbox__input.is-checked .el-checkbox__inner::after{
                                                                                                                                                                                                                                                                                                                                              content: "\e715";
                                                                                                                                                                                                                                                                                                                                              position: absolute;
                                                                                                                                                                                                                                                                                                                                              width: 100%;
                                                                                                                                                                                                                                                                                                                                              height: 100%;
                                                                                                                                                                                                                                                                                                                                              top: 50%;
                                                                                                                                                                                                                                                                                                                                              left: 50%;
                                                                                                                                                                                                                                                                                                                                              transform: translate(-50%, -50%);
                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                            >>>.el-checkbox__inner::after{
                                                                                                                                                                                                                                                                                                                                                 content: "";
                                                                                                                                                                                                                                                                                                                                              position: absolute;
                                                                                                                                                                                                                                                                                                                                              width: 100%;
                                                                                                                                                                                                                                                                                                                                              height: 100%;
                                                                                                                                                                                                                                                                                                                                              top: 50%;
                                                                                                                                                                                                                                                                                                                                              left: 50%;
                                                                                                                                                                                                                                                                                                                                              transform: translate(-50%, -50%);
                                                                                                                                                                                                                                                                                                                                            } */
  @media screen and (max-width: 1664px) {
    .topbutton {
      margin-bottom: 13px;
      margin-top: 20px;
    }
    .date-picker-icon {
      width: 153px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .yscwj {
      width: 77px;
      height: 16px;
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 14px;
      margin-top: 33px;
    }
    .second_title_box {
      margin-top: 13px;
      margin-bottom: 20px;
    }
    .second_title1 {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #162747;
    }
    .f3_2 {
      width: 306px;
      height: 306px;
      background: #f7fafc;
      border-radius: 8px;
      margin-right: 20px;
      margin-top: 20px;
    }
    .c {
      margin-left: 13px;
    }
    .ygrs {
      margin-bottom: 13px;
      margin-top: 20px;
    }
    .ygrs2 {
      margin-top: 13px;
      float: left;
      width: 760px;
    }
    .ygrs3 {
      margin-top: 18px;
      margin-bottom: 18px;
    }
    .anniu {
      width: 106px;
      height: 26px;
      background-color: #339b9a;
      border-color: #339b9a;
      font-size: 12px;
    }

    .dinput {
      width: 733px;
      display: flex;
      float: left;
      justify-content: space-between;
      align-items: center;
    }
    .mgtop {
      margin-top: 13px;
    }
    .f {
      width: 306px;
      height: 266px;
      background: #f7fafc;
      border-radius: 8px;
      margin-right: 20px;
      margin-top: 13px;
    }
    .gdpgzx_select {
      width: 153px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .inputk {
      width: 269px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .inputk2 {
      width: 269px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
      margin-left: 20px;
    }
    .inputk3 {
      width: 269px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
      margin-left: 20px;
      margin-right: 13px;
    }
    .f2 {
      width: 306px;
      height: 593px;
      background: #f7fafc;
      border-radius: 8px;
      margin-top: 13px;
    }
    .dxk {
      margin-top: 3px;
    }
    .f3 {
      width: 306px;
      height: 306px;
      background: #f7fafc;
      border-radius: 8px;
      margin-top: 20px;
      margin-right: 20px;
    }
    .f4 {
      width: 960px;
      height: 266px;
      background: #f7fafc;
      border-radius: 8px;
      margin-left: 20px;
    }
    .f4_1 {
      margin-left: 13px;
    }
    .fl {
      margin-left: 13px;
      margin-right: 13px;
    }
    .tb i {
      font-size: 14px;
      color: #7c969b;
    }
    .topbutton2 {
      margin-top: 13px;
    }
    .dk3_1 {
      width: 652px;
      height: 606px;
      display: flex;
      flex-wrap: wrap;
    }
    .a {
      width: 1000px;
      height: auto;
      /* height: 1165px; */
      padding-bottom: 20px;
      background: #ffffff;
      display: flex;
      border-radius: 6px;
      margin: 0 auto;
    }
    .dk3 {
      width: 960px;
      display: flex;
      float: left;
      margin-left: 20px;
      height: 606px;
    }
    .mc {
      width: 100%;
      /* width: 198px; */
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .mc2 {
      width: 86px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .mc3 {
      width: 186px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .dk3_2 {
      display: flex;
      width: 300px;
      height: 613px;
    }
    .dk4 {
      float: left;
      height: 266px;
      margin-top: 20px;
    }
    .dk4_1 {
      height: 266px;
      display: flex;
      float: left;
      align-items: center;
      flex-wrap: wrap;
    }
    .dk5 {
      height: auto;
      /* height: 185px; */
      float: left;
      margin-top: 20px;
    }
    .dk5_1 {
      height: auto;
      /* height: 185px; */
      float: left;
    }
    .qscwjzl {
      margin-top: 20px;
      font-size: 10px;
    }
    .heard {
      margin-top: 20px;
    }
    .gdpgzx {
      float: left;
      margin-left: 20px;
    }
    .year {
      float: left;
      margin-left: 26px;
    }
    .cgbutton {
      float: right;
      margin-right: 20px;
    }
    .djsc2 {
      margin-bottom: 33px;
    }
    .f5 {
      width: 960px;
      height: auto;
      /* height: 185px; */
      padding-bottom: 20px;
      background: #f7fafc;
      border-radius: 8px;
      margin-left: 20px;
      float: left;
    }
    .gdpgzx span {
      font-size: 9px;
    }
    .year span {
      font-size: 9px;
    }
    .in {
      width: 950px;
    }
    .djsc {
      width: 106px;
      height: 26px;
      background: #339c9b;
      border-radius: 21px;
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 14px;
    }
    >>> #gs .el-form-item {
      padding: 0;
      margin: 0;
    }

    >>> .el-input__prefix {
      margin-left: 130px;
    }
    >>> .el-form-item__label {
      width: 75px !important;
      height: 16px;
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 25px;
      padding: 0;
      margin-right: 6px;
    }
    >>> .el-card__body {
      padding: 0;
    }
    >>> .limit_width {
      width: 1000px;
      height: auto;
      margin: 0 auto;
      padding-top: 96px;
      padding-bottom: 26px;
    }
    >>> .el-checkbox__label {
      display: inline-block;
      padding-left: 6px;
      line-height: 12px;
      font-size: 8px;
    }
    >>> .el-form-item {
      padding: 0;
      margin: 0;
      /* width: 390px; */
    }
    >>> .el-upload-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      /* margin-top: 20px; */
    }
    >>> .el-upload {
      width: 72px;
      height: 30px;
      margin-top: 13px;
    }
    >>> .el-upload-list__item {
      float: left;
      width: 80px;
      height: 26px;
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #142746;
      line-height: 23px;
      margin-top: 0px;
      margin-bottom: 13px;
      border-radius: 2px;
      margin-right: 20px;
      /* margin-left: 20px; */
      background: #ffffff;
      cursor: pointer;
    }
    >>> .el-upload-list__item:last-child {
      margin: 0;
    }
    /* >>>.el-upload-list__item:not(:last-child){
                                                                                                                                                                                                                                                                                                                                                    margin-bottom: 0;
                                                                                                                                                                                                                                                                                                                                                } */
    >>> .el-upload-list__item-name {
      float: left;
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #142746;
      line-height: 17px;
      margin-left: 11px;
      margin-top: 6px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
    >>> .el-upload-list__item-name [class^='el-icon'] {
      display: none;
    }
    >>> .el-button {
      line-height: 0;
    }
    /deep/#bs .el-form-item__label {
      width: 87px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 25px;
      padding: 0;
      margin-right: 6px;
      float: left;
    }
    >>> .mc3 .el-input__inner {
      width: 186px;
      height: 26px;
    }
    >>> .mgtop .el-form-item__content {
      /* width: 186px; */
      height: 26px;
      /* float: left; */
    }
    >>> .el-form-item__content {
      margin-left: 0px !important;
      /* width: 198px; */
      height: 26px;
      /* float: left; */
    }

    >>> .in .el-form-item__content {
      width: 970px;
    }

    >>> .mc .el-input__inner {
      width: 100%;
      /* width: 198px; */
      height: 26px;
    }
    >>> .inputk .el-input__inner {
      width: 269px;
      height: 26px;
    }
    >>> .inputk2 .el-input__inner {
      width: 269px;
      height: 26px;
    }
    >>> .inputk3 .el-input__inner {
      width: 269px;
      height: 26px;
    }
    >>> .gdpgzx_select .el-input .el-input__inner {
      width: 153px;
      height: 26px;
    }
    >>> .year .date-picker-icon .el-input__inner {
      width: 153px;
      height: 26px;
    }
    ::v-deep .el-checkbox__inner {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    ::v-deep .el-checkbox__inner::after {
      width: 3px;
      height: 7px;
      top: 2px;
      left: 5px;
    }
  }
</style>